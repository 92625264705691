import React, {Component, useEffect, useState} from 'react';
import Logger from "../models/Logger";
import Router from "./Router";
//Redux
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import reducer from '../globalState'
import { composeWithDevTools } from 'redux-devtools-extension'
import createHistory from 'history/createHashHistory'
import { routerMiddleware } from 'react-router-redux'
import { useClearCache } from 'react-clear-cache';


// import store from './Store/store';
const history = createHistory()
const router = routerMiddleware(history)
const middlewares = [router, thunk]

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)))

const App = ()=>{

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  useEffect(() => {
    if(!isLatestVersion){
      emptyCacheStorage();
    }
  }, [])

  return (
    <Provider store={store}>
          <React.Fragment>
          <Router/>
      </React.Fragment>
    </Provider>
  )
  
}

export default App;
