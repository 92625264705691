import React from "react";

const PageSection = ({
  listId,
  handleBlurOrder,
  section,
  handleSelectPageChange,
  contents_length,
  contents,
  handleFirstButton,
  handleSecondButton,
  option,
  firstButtonText,
  secondButtonText,
  secondButtonStyle,
  firstButtonStyle,
  validPage = {
    order: false,
    page: false,
  },
  orderMessage

}) => {

  return (
    <>
      <div className="">
        <hr />
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12 ">
                <span className="carrusel--subtitle">Página {option.active}</span>
                <select
                  className="form-select form-select-lg"
                  name="page"
                  aria-label="Default select example"
                  idsection={section.section_id}
                  nombreoption={option.name}
                  idopcion={option.option_id}
                  value={option.content_id}
                  field="content_id"
                  onChange={handleSelectPageChange}
                >
                  <option defaultValue value="0">
                    Seleccione la página correspondiente
                  </option>
                  {contents_length > 0 &&
                    contents.map((page) => (
                      <option key={page.id} value={page.id}>
                        {page.nombre}
                      </option>
                    ))}
                </select>
                {!!validPage &&
                  !validPage.page && (
                    <span> Debe seleccionar una página </span>
                  )}
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 ">
                <span className="carrusel--subtitle">Orden</span>
                <input
                  disabled={ !option.active}
                  type="number"
                  className="form-control"
                  //readOnly
                  idsection={section.section_id}
                  idopcion={option.option_id}
                  value={option.order}
                  field="order"
                  order={listId}
                  onChange={handleSelectPageChange}
                  onBlur={handleBlurOrder}
                />
                {!!validPage &&
                  (!validPage.order && (
                    <span> El orden es obligatorio y no puede estar repetido </span>
                  ))}
                {Object.keys(orderMessage).length > 0 &&
                  orderMessage.idopcion == option.option_id &&
                  orderMessage.bool && (
                    <span>El número de orden {orderMessage.value} ya existe</span>
                  )
                }
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 d-grid mt-2">
            <button
              disabled={ !option.active}
              className={firstButtonStyle}
              idsection={section.section_id}
              idopcion={option.option_id}
              nombreseccion={section.name}
              onClick={handleFirstButton}
            >
              {firstButtonText}
            </button>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 d-grid mt-2">
            <button
              className={secondButtonStyle}
              idsection={section.section_id}
              idopcion={option.option_id}
              optionactive={option.active ? '1':'0'}
              nombreseccion={section.name}
              onClick={handleSecondButton}
            >
              {secondButtonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSection;
