import React, { Component } from "react";
import { withRouter } from "react-router";
import settings from "../../settings";
import MaintPage from "../StatusViews/MaintPage";

@withRouter
class StaticPageStatus extends Component {
  static status = [
    {
      key: ["maintenance", "mantenimiento", "maint"],
      page: <MaintPage />
    }
  ];
  render() {
    const { children } = this.props;
    let render = children;
    const { location } = this.props;
    const key = Object.keys(settings.maintenance_pages);
    const keyfound = key.find(page => page === location.pathname);

    if (keyfound) {
      StaticPageStatus.status.map(s => {
        const statusFound = new RegExp(s.key.join("|"), "i").test(
          settings.maintenance_pages[keyfound]
        );
        if (statusFound && !!s.page) {
          render = s.page;
        }
      });
    }
    return render;
  }
}

export default StaticPageStatus;
