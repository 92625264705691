import FetchResponses from "../../models/FetchResponses";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import settings from "../../settings";
import { Redirect } from "react-router";
import ReactHtmlParser from "react-html-parser";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionsList: [],
      contents: [],
      contents_length: 0,
      headercontent: [],
      loading: false,
      hide_mobile: true,
      arrayToShow: [],
      showFirstMenu: false,
      showSecondMenu: false,
      menu2: {},
      menu1: {},
      header: '',
      banner: {},
    };
  }

  installPrompt = null;
  buttonInstall;

  componentDidMount = () => {
    this.setState({ loading: true });
    let url = settings.backend.listSections;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    }).then(FetchResponses.processResponse)
      .then((response) => {
        if (response) {
          this.setState({ sectionsList: response.contents, loading: false });
          let arrayToShow = [];
          arrayToShow = this.state.sectionsList
            .filter((item) => item.menu === true)
            .map((section) => {
              return {
                section_id: section.section_id,
                show: false,
              };
            });
          this.setState({ arrayToShow });
        }
      })
      .catch(this.failureDataResponse);

    //it brings the list of sections
    url = settings.backend.listMenu;

    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    }).then(FetchResponses.processResponse)
      .then((response) => {
        if (response) {          
          let header = response.Header.filter((item) => item.active === true)
            .sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            });
          header.length > 0
            ? this.setState({ menu1: response.menu1, menu2: response.menu2, header: header[0].detail })
            : this.setState({ menu1: response.menu1, menu2: response.menu2, header: '' });
        }
      })
      .catch(this.failureDataResponse);
        
      this.buttonInstall = document.getElementById('install');
      window.addEventListener('beforeinstallprompt', e => {
        // For older browsers
        e.preventDefault();        
        this.installPrompt = e;
        // See if the app is already installed, in that case, do nothing
        if ((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true) {          
          return false;
        }
     
        this.buttonInstall.style.display = 'block'
        this.buttonInstall.scrollIntoView()
      });
  
      this.buttonInstall.addEventListener('click', e => {
        window.dataLayer.push({event: 'ga_event',category: 'pwa',action: 'show',label: 'prompt'}); 
        this.installPrompt.prompt();
        this.installPrompt.userChoice.then((choice) => {
          if (choice.outcome !== 'accepted') {
            window.dataLayer.push({
              event: 'ga_event',
              category: 'pwa',
              action: 'close',
              label: 'prompt'
            });
          }
          this.installPrompt = null;
        })   
      });

      window.addEventListener('appinstalled', e => {
        window.dataLayer.push({
          event: 'ga_event',
          category: 'pwa',
          action: 'instalacion'
        });
      });     
  };

  onClickShowMenu = (event) => {
    let hide_mobile = !this.state.hide_mobile;

    if (!this.state.hide_mobile) {
      this.setState({
        hide_mobile,
        showFirstMenu: false,
        showSecondMenu: false,
      });
    } else {
      this.setState({ hide_mobile });
    }
  };

  handleOnClickSubMenu = (event) => {
    let section_id_filter = event.target.getAttribute("section_id");
    let arrayToShow = this.state.arrayToShow.map((item) => {
      if (Number(item.section_id) === Number(section_id_filter)) {
        //Change the state to show the sub menu
        let item_aux = {
          section_id: item.section_id,
          show: !item.show,
        };
        return item_aux;
      } else {
        let item_aux = {
          section_id: item.section_id,
          show: false,
        };
        return item_aux;
      }
    });

    this.setState({ arrayToShow });
  };

  handleOnClickToogleFirstMenu = (event) => {
    this.cleanShowStates();
    let showFirstMenu = !this.state.showFirstMenu;
    this.setState({ showFirstMenu, showSecondMenu: false });
  };

  handleOnClickToogleSecondMenu = (event) => {
    this.cleanShowStates();
    let showSecondMenu = !this.state.showSecondMenu;
    this.setState({ showFirstMenu: false, showSecondMenu });
  };

  cleanShowStates = () => {
    let arrayToShow = this.state.arrayToShow.map((item) => {
      let item_aux = {
        section_id: item.section_id,
        show: false,
      };
      return item_aux;
    });
    this.setState({ arrayToShow, showFirstMenu: false });
  };

  render() {
    let logoSesion = (
      <img
        className="nav-logo-sesion"
        alt="logo-sesion"
        src="https://pse-alkomprar.s3.amazonaws.com/paginaPse/session.png"
      />
    );


    return (
      <>
       
        <div className="full-fill"></div>
        <div className="nav-container">
          <div className="nav-flex">
            <div className="nav-flex-container nav-flex-main">
              <input type="checkbox" id="checkbox" />         
              <button
                className="checkbtn"
                type="button"
                onClick={this.onClickShowMenu}>
                <label htmlFor="checkbox">
                  <i className="fa fa-bars" aria-hidden="true" />
                </label>
              </button>

              <div className="nav-flex-logo align-vertical">
                <Link to="/" className="navbar-brand align-vertical">
                  {/* {this.state.header} */}
                  <div className="ck-content landing-item" style={{ width: "100%" }}>
                    <div>{ReactHtmlParser(this.state.header)} </div>
                  </div>
                </Link>
              </div>
              
              <div className={
                this.state.hide_mobile
                  ? "hide nav-iniciar-sesion"
                  : "nav-iniciar-sesion "
              }>
                <div className="align-vertical">{logoSesion}</div>
                <div className="align-vertical">
                  <Link
                    to="login"
                    className="nav-link active"
                    aria-current="page"
                    style={{ color: "#ff0000" }}>
                    INICIAR SESIÓN
                  </Link>
                </div>
              </div>
              
            </div>
          </div>
          <div className="nav-flex-container-menu">
            <div
              className={
                this.state.hide_mobile
                  ? "hide nav-flex-container nav-flex-responsive"
                  : "nav-flex-container nav-flex-responsive"
              }>
              <div className="nav-flex-menu">
                {Object.keys(this.state.menu2).length > 0 && (
                  <>
                    <button
                      href="#"
                      className="non-button"
                      onClick={this.handleOnClickToogleFirstMenu}
                    >
                      {" "}
                      {this.state.menu2.name}{" "}
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </button>
                    <div className={
                      this.state.arrayToShow.length > 0 &&
                        this.state.showFirstMenu
                        ? "show-submenu nav-flex-submenu "
                        : "nav-flex-submenu "
                    }>
                      {this.state.menu2.options.length > 0 &&
                        this.state.menu2.options.sort(function (a, b) {
                          if (a.order > b.order) {
                            return 1;
                          }
                          if (a.order < b.order) {
                            return -1;
                          }
                          return 0;
                        }).map((section, index) => {
                          return (
                            <div key={index} className="nav-flex-box">
                             
                              <button
                                href="#"
                                onClick={this.handleOnClickSubMenu}
                                section_id={section.section_id}
                                className="non-button font-arial"
                                style={{ color: "#ff0000"}}>
                                {" "}
                                {section.name}{" "}
                              </button>
                              <div
                                
                                className={
                                  this.state.arrayToShow.length > 0 &&
                                  (this.state.arrayToShow.filter(
                                    (item) =>
                                      item.section_id == section.section_id
                                  )[0].show
                                    ? "show-submenu nav-flex-options"
                                    : "nav-flex-options")
                                }>
                                <ul>
                                  {section.options.map((item, key) => {
                                   
                                    if (item.active) {
                                      if (item.page) {
                                        return (
                                          <li key={key}>
                                            <Link

                                              key={item.option_id}
                                              to={
                                                this.props.mainpage
                                                  ? `mainpage/${item.content_id}`
                                                  : `${item.content_id}`
                                              }
                                              className="font-arial"
                                            >
                                              {item.name}
                                            </Link>
                                          </li>
                                        );
                                      } else {
                                        return (
                                          <li key={key}>
                                            <a key={item.option_id}
                                              target="_blank"
                                              href={`${item.url}`}
                                              rel="noopener noreferrer"
                                              className="font-arial"
                                            >
                                              {item.name}
                                            </a>
                                          </li>
                                        );
                                      }
                                    }
                                  })}
                                </ul>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
             
              <div className="nav-flex-menu">
                {Object.keys(this.state.menu1).length > 0 && (
                  <>
                    <button
                      href="#"
                      onClick={this.handleOnClickToogleSecondMenu}
                      className="non-button font-arial"
                    >
                      {" "}
                      {this.state.menu1.options[0].name}{" "}
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </button>
                    <div
                      className={
                        this.state.arrayToShow.length > 0 &&
                          this.state.showSecondMenu
                          ? "show-submenu nav-flex-submenu"
                          : "nav-flex-submenu"
                      }>
                      <div className={
                        this.state.arrayToShow.length > 0 &&
                          this.state.showSecondMenu
                          ? "show-submenu nav-flex-box"
                          : "nav-flex-box"
                      }>
                        <ul>
                          {this.state.menu1.options.length > 0 &&
                            this.state.menu1.options[0].options.sort(function (a, b) {
                              if (a.order > b.order) {
                                return 1;
                              }
                              if (a.order < b.order) {
                                return -1;
                              }
                              return 0;
                            }).map((item, index) => {
                              if (item.active) {
                                if (item.page) {
                                  return (
                                    <li key={index}>
                                      <Link
                                        className="font-arial"
                                        key={item.option_id}
                                        to={
                                          this.props.mainpage
                                            ? `mainpage/${item.content_id}`
                                            : `${item.content_id}`
                                        }
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li key={index}>
                                      <a
                                        className="font-arial"
                                        key={item.option_id}
                                        target="_blank"
                                        href={`${item.url}`}
                                        rel="noopener noreferrer"
                                      >
                                        {item.name}
                                      </a>
                                    </li>
                                  );
                                }
                              }
                              
                            })}
                        </ul>
                      </div>
                    </div>

                  </>
                )}
              </div>

              <div className="nav-flex-menu">
                <Link to="/consulta"
                >
                  {
                    //Se pone esta condicion para que aparezca al tiempo con los otros menús
                    Object.keys(this.state.menu2).length > 0 &&(
                      <button className="non-button font-arial" type="button">
                        Pagos en linea PSE
                      </button>
                    )
                  }
                </Link>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
            <button className="non-button button-install" style={{display:"none"}} id="install" >Instalar App</button>      
          </div>
        
        </div>
      </>
    );
  }
}

export default Navbar;
