import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import settings from "../../settings";
import Logger from "../../models/Logger";
import FetchResponses from "../../models/FetchResponses";
import {
  translateDetails,
} from "../../models/Messages";
import FacturaView from "./FacturaView";
import Navbar from "./Navbar";
// import HeaderOld from "./HeaderOld";
//import Navbar from './Navbar';

//Public views
class FacturasPublicas extends Component {
  constructor(props) {
      super(props);
      this.state = {
        clientInvoices:{},
        carteraCastigada:false,
      };
      Logger.setLogger(this.constructor.name);
    }

  componentDidMount() {
    this.setState({
      loading: true
    });
    if(this.props.location.state){
      let clientInvoices =this.props.location.state.clientInvoices
      let carteraCastigada=this.props.location.state.carteraCastigada
      this.setState({clientInvoices,carteraCastigada})
    }

  }
  
  render() {
    if(this.props.location.state){
      return <>
      {/* <Navbar mainpage/> */}
      <Navbar mainpage />
      <FacturaView 
        clientInvoices={this.state.clientInvoices} 
        loading={this.state.loading} 
        carteraCastigada={this.state.carteraCastigada}
        publicView
      />
      </>
    }else{
      return <Redirect to={'/'}/>
    }
      
  }
    
}

export default FacturasPublicas