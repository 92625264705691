import React, { Component } from "react";
import HeaderPrivate from "./HeaderPrivate";
import MenuList from "./MenuList";
//import Footer from "../PublicViews/Footer";
import Footer from "../PublicViews/Footerpersonalizado";
import Logger from "../../models/Logger";
import Auth from "../../models/Auth";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import { Messages, translateTransaction } from "../../models/Messages";
import Error from "../PublicViews/Error";
import { Link, Redirect } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import Pagination from "react-js-pagination";
// redux
import { connect } from "react-redux";
import { SetMenuCss } from "./Menu_Actions";
import TransactionCollapse from "./TransactionCollapse";
import StaticPageStatus from "../SharedViews/StaticPageStatus";
import { ModalAux } from "./ModalAux";

// import bootstrap from 'bootstrap';

class AumentarCupo extends Component {
  constructor(props) {
    super(props);
    
    let last_user = JSON.parse(Auth.getLastUserData())
    this.state = {
      loading: false,
      open: false,
      error_consulta:false,
      error_message: Messages.errorTryLater,
      fiscal_number: last_user && last_user.fiscal_number ? last_user.fiscal_number : '_',
      gestionar_aumento_cupo:false,
      cupo_actual:'0',
      nombre:'',
      apellido:'',
      correo:'',
      celular:'',
      direccion:'',
      actividadEconomica:'',
      clientInfo:{}

    };
    this.pdf_window = null;
    Logger.setLogger(this.constructor.name);
  }

  componentDidMount() {
    //Cambia el estado del menu
    this.props.SetMenuCss(["", "", "", "", "", "","submit"]);
    localStorage.setItem(
      "menu",
      JSON.stringify(["", "", "", "", "", "","submit"])
    );

    this.revisarDatosCliente();
  }


  revisarDatosCliente=()=>{

    this.setState({loading:true})
    let url=settings.backend.infoClientCredit+this.state.fiscal_number;
    fetch(url, {
      method: 'GET',
      headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then((response)=>{
        
        if(response.estado == "ok"){
          this.setState({
            loading:false,
            nombre:response.nombres,
            apellido:response.apellidos,
            correo:response.correoElectronico,
            celular:response.celular,
            actividadEconomica:response.actividadEconomica,
            direccion:response.direccion,
            cupo_actual:Number(response.cupoActual),
          })
        }else{
          this.failConsultInvalidParameters(response.mensaje)
        }
        
      })
      .catch(this.failConsult)
  }

  failConsult=()=>{
    this.setState({
      loading:false,
      open:true,
      error_message:Messages.errorTryLater
    })
  }

  failConsultInvalidParameters=(message)=>{
    this.setState({
      loading:false,
      open:true,
      error_message:message
    })
  }


  handleConfirmarDataCliente=(event)=>{

    event.preventDefault();
    this.setState({loading:true})
    let url=settings.backend.manageCreditLine+this.state.fiscal_number;
    fetch(url, {
      method: 'GET',
      headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then((response)=>{
        if(response.estado == 'ok' && (response.Aplicaparacredito).toLowerCase() !== 'false' ){
          this.setState({
            loading:false,
            gestionar_aumento_cupo:true,
            clientInfo:response
          })
         
        }else{
          this.failConsultInvalidParameters(response.mensaje)
        }
      })
      .catch(this.failConsult)
  }

  handleCloseModal=()=>{
    this.setState({ open: false, error_consulta:true})
  }

  element = () => (
    <div className="container-fluid">
      <div className="sidebar">
        <MenuList disabled={this.state.loading} />
      </div>
      <div className="row">
        <div className="col private-wrapper">
          <HeaderPrivate menu_list={this.state.loading} />
          
              <div className="wrapper">
                <StaticPageStatus>
                  <div className="row justify-content-center">

                  
                      <div className="col-lg-10 col-xl-8">
                        <div className="row"> 
                          <div className="col-lg-12" >
                              <div className="panel row pe-4 ps-4 justify-content-md-cente mb-5 pb-5  mt-5 pt-5"  style={{height:this.state.open && '300px'}}>
                                  
                                  <div className="col-lg-12 text-center" >
                                      <p className="p_borderbotton--gray">
                                          Cupo actual 
                                          <span style={{fontWeight:"bold", fontSize:'1.8rem'}}> $ {this.state.cupo_actual.toLocaleString('de-DE', {
                                            maximumFractionDigits: 0,
                                          })}</span>
                                      </p>
                                  </div>

                                  <div className="col-lg-12 text-center">
                                      <p className="text-center pe-2 ps-2 ">
                                        Confirma tus datos a continuación
                                      </p>
                                  </div>

                                  { (this.state.loading) ? (
                                    <div className="row justify-content-center">
                                      <BarLoader
                                        sizeUnit="%"
                                        height={5}
                                        width={500}
                                        color="#ff0000"
                                        loading={this.state.loading}
                                      />
                                    </div>
                                  ):<>
                                    <div className="col-lg-12">

                                    {!this.state.open && (
                                      <form onSubmit={this.submitAumentoCupo} autoComplete="off" >

                                          <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                              <input  
                                                  type="text" 
                                                  name="nombre" 
                                                  id="nombre"
                                                  className="form-control "  
                                                  placeholder="NOMBRE"
                                                  value={this.state.nombre}
                                                  required
                                                  readOnly
                                              />
                                              <label htmlFor="nombre" style={{fontSize:'0.8em'}}>Nombre</label>
                                          </div>

                                          <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                              <input  
                                                  type="text" 
                                                  name="apellido" 
                                                  id="apellido"
                                                  className="form-control "  
                                                  placeholder="APELLIDO"
                                                  value={this.state.apellido}
                                                  required
                                                  readOnly
                                              />
                                              <label htmlFor="monto" style={{fontSize:'0.8em'}}>Apellido</label>
                                          </div>

                                          <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                              <input  
                                                  type="text" 
                                                  name="correo" 
                                                  id="correo"
                                                  className="form-control "  
                                                  placeholder="CORREO"
                                                  value={this.state.correo}
                                                  required
                                                  readOnly
                                              />
                                              <label htmlFor="correo" style={{fontSize:'0.8em'}}>Correo</label>
                                          </div>

                                          <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                              <input  
                                                  type="text" 
                                                  name="celular" 
                                                  id="celular"
                                                  className="form-control "  
                                                  placeholder="CELULAR"
                                                  value={this.state.celular}
                                                  required
                                                  readOnly
                                              />
                                              <label htmlFor="celular" style={{fontSize:'0.8em'}}>Celular</label>
                                          </div>

                                          <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                              <input  
                                                  type="text" 
                                                  name="direccion" 
                                                  id="direccion"
                                                  className="form-control "  
                                                  placeholder="DIRECCION"
                                                  value={this.state.direccion}
                                                  required
                                                  readOnly
                                              />
                                              <label htmlFor="direccion" style={{fontSize:'0.8em'}}>Dirección</label>
                                          </div>

                                          <div className="mb-3 col-sm-6 offset-sm-3 form-floating">
                                              <input  
                                                  type="text" 
                                                  name="actividadEconomica" 
                                                  id="actividadEconomica"
                                                  className="form-control "  
                                                  placeholder="ACTIVIDAD ECONÓMICA"
                                                  value={this.state.actividadEconomica}
                                                  required
                                                  readOnly
                                              />
                                              <label htmlFor="direccion" style={{fontSize:'0.8em'}}>Actividad económica</label>
                                          </div>

                                          <div className="col-10 offset-1 col-sm-6 offset-sm-3 d-grid align-self-center">
                                            <p style={{fontWeight:'bold'}}>
                                              Por favor verifica el correo y celular a los cuales enviaremos información, ten en cuenta que, 
                                              con esta actividad económica registrada por ti, se realiza el análisis crediticio, en caso de
                                              que no sean tus datos actuales, dirígete a un punto de venta para que actualices tus datos y
                                              sigas disfrutando de nuestros servicios y beneficios.
                                            </p>
                                          </div>

                                          <div className="col-10 offset-1 col-sm-4 offset-sm-4 d-grid align-self-center">
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={this.handleConfirmarDataCliente}
                                              // disabled={!(this.state.checkCantidadCuotas && this.state.checkCuotaMensual)}
                                            >
                                              Confirmar <span className="icon dripicons-chevron-right icon-align-vertical" />
                                            </button>
                                          </div>

                                      </form>
                                    )}
                                    </div>
                                  </>  
                                }

                              </div>
                          </div>
                        </div>  
                      </div>   
                 
                </div>
                  
                </StaticPageStatus>
              </div>
          
          
          <Footer />
        </div>
      </div>

      <Error
        open={this.state.open}
        error_message={this.state.error_message}
        handleClose={this.handleCloseModal}
      />

    </div>
  );

  render() {
    if (this.state.error_consulta){
      return <Redirect to={{pathname: '/resumen'}} />
    }else if(this.state.gestionar_aumento_cupo){
      return <Redirect to={{
        pathname: '/aumentar_cupo/gestionar',
        state: { 
          clientInfo : this.state.clientInfo,
          fiscal_number:this.state.fiscal_number,
          identified:true
        }
    }} />
    }

    return Auth.authenticationRequired(this.element());
  }
}

const mapStateToProps = state => ({
  menu: state.menu.menu
});

export default connect(mapStateToProps, { SetMenuCss })(AumentarCupo);
