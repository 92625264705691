import {
  setDataFooter,
} from '../../globalState/app';

export const setDataFooterTotal = (data) => async (dispatch, getState) => {
  dispatch({
    type: setDataFooter,
    payload: data,
  })
}
