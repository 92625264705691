import {
    setDataFooter,
} from '../../../globalState/app';

//state inicial, cada reducer debe tener su propio state
const initialState = {
    footer:  '',
  }
  

export default function(state = initialState, action){
    switch(action.type){
        case setDataFooter:
            return{
                ...state,
                footer: action.payload
            }
        default:
            return state;
    }
}
