import React, { Component } from "react";
import Footer from "./Footerpersonalizado";
import Carrusel from "./Carrusel";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import Navbar from "./Navbar";
import WhatsAppButton from "./WhatsappButton";
import TabsMenu from "./TabsMenu";
import Aliados from "./Aliados";
import LandingMain from "./LandingMain";
import { Helmet } from "react-helmet";
import StaticPageStatus from "../SharedViews/StaticPageStatus";


class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionsList: [],
      contents: [],
      contents_length: 0,
      creditos: [],
      aliados: [],
      landing: [],
      footer: [],
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    let url = settings.backend.listContent;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    }).then(FetchResponses.processResponse)
      .then((response) => {
        this.setState({
          contents: response.contents,
          contents_length: response.contents_length,
          loading: false,
        });
      }).catch(this.failureDataResponse);

    //Brings the list of sections
    url = settings.backend.listSections;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    }).then(FetchResponses.processResponse)
      .then((response) => {
        if (response) {
          this.setState({ sectionsList: response.contents });
        }
      })
      .catch(this.failureDataResponse);
    //it brings all the sections to be rendered 
    url = settings.backend.listMenu;

    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    }).then(FetchResponses.processResponse)
      .then((response) => {
        if (response) {
          this.setState({
            creditos: response.Creditos,
            aliados: response.Aliados,
            landing: response.Landing,
            footer: response.Footer,
          });
        }
      })
      .catch(this.failureDataResponse);
  };

  updateContentState = () => {
    this.setState({ loading: true });
    let url = settings.backend.listContent;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    }).then(FetchResponses.processResponse)
      .then((response) => {
        this.setState({
          contents: response.contents,
          contents_length: response.contents_length,
          loading: false,
        });
      })
      .catch(this.failureDataResponse);
  };

  render() {
    return (
      <StaticPageStatus>
        <Helmet>
          <title>Crédito 20 minutos | Solicitar crédito en línea con cedula </title>
          <meta name="description" content="Crédito 20 minutos: Alkomprar, Alkosto, Ktronix, Kalley, AKT [crédito en minutos]. Ingresa ahora y solicita créditos rápidos y fáciles. Pagos crédito 20 min" />
        </Helmet>
        <WhatsAppButton />
        <Navbar mainpage />
        <div className="body-container">
          <div className="hero-section">
            <div className="container">
              <div className="row"></div>
            </div>
            <Carrusel type="Slider"/>
          </div>
          <main className="flex-shrink-0">
            <div className="container">
              <Aliados section={this.state.aliados} />
              <TabsMenu section={this.state.creditos} />
              <LandingMain section={this.state.landing} />
            </div>
          </main>
        </div>
        <Footer main_background />
      </StaticPageStatus>
    );
  }
}

export default MainPage;
