
import React from 'react';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module'

const CirculoInformacion = ({ nombre, link, icon, loading, src }) => {
  let propiedadCursor=(link==="#")?'default':'pointer';

  const sendDataLayerHeaderInteraction =()=>{
  
    let tagManagerArgs = {
      event: 'Interaciones-Header',
      category:'Header Interaction',        
      action:'Click',
      label: nombre
    }  
    TagManager.dataLayer({
      dataLayer: tagManagerArgs
    })

  }

  return (
    <Link to={link} onClick={sendDataLayerHeaderInteraction}  className={`animated fadeIn ${loading ? 'disabled' : ''} `  }>
     
      <div style={{
        width: '8em',
        height: '8em',
        backgroundColor: 'red',
        borderRadius: '100%',
        cursor:propiedadCursor
      }}>
        <img src={src} alt="boton" />
      </div>
    </Link>
  )
}

export default CirculoInformacion;