import React, { Component } from "react";
import { Link } from "react-router-dom";

// import LogoCredito from "../../images/logo-cred20min.png";

class HeaderLogo extends Component {
  AlkomprarLogo='https://pse-alkomprar.s3.amazonaws.com/paginaPse/logo.png';
  render() {
    return (
      <div className="HeaderPublic d-flex align-items-center">
        <Link to="/" className="align-items-center" style={{width: '50%'}}>
          <img alt="AlkomprarLogo" src={this.AlkomprarLogo} className="img-responsive alkomprar-logo" />
        </Link>
        {/*<img alt="logoCredito20Min" src={LogoCredito} className="img-responsive logoCredito20Min"/>*/}
      </div>
    );
  }
}

export default HeaderLogo;
