import React, { Component } from 'react'
import Img from '../../images/MantenimientoAlkomprar.jpg'

class MaintPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-12 col-xl-10">
          <div className="panel usuario">
            <div
              style={{
                padding: '7rem 7rem',
              }}
            >
              <div className="row">                               
                  <img src={Img} className="img-fluid" alt="Responsive" />               
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    )
  }
}

export default MaintPage
