import {
    setDataPerfil
} from '../../../globalState/app';

//state inicial, cada reducer debe tener su propio state
const initialState = {
    perfilInformation: ''
  }
  

export default function(state = initialState, action){
    switch(action.type){
        case setDataPerfil:
            return{
                ...state,
                perfilInformation: action.payload,
            }
        default:

            return state;
    }
}
