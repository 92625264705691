import React, { Component } from 'react'

class WhatsAppButton extends Component {

  render() {
    const style = {
      position: 'fixed',
      width: '60px',
      height: '30px',
      bottom: '10em',
      right: '40px',
      backgroundColor: '#25d366',
      color: '#FFF',
      borderRadius: '50px',
      textAlign: 'center',
      fontSize: '30px',
      zIndex: '100',
      marginTop: '13px'
    }
    return (
      <a href="https://api.whatsapp.com/send?phone=573336495765" className="float" target="_blank">
        {/* <a href="https://api.whatsapp.com/send?phone=573336495765" > */}
        <i className="fa fa-whatsapp  my-float"></i>
      </a>
    )
  }
}

export default WhatsAppButton;