import React, { Component } from 'react'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-pys-custom-build";
import ReactHtmlParser from "react-html-parser";

class Editor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editor: '',
      editor2: '',
      show: false
    };
  }
  // handleOnChangeCkeditor=(event, editor) => {
  //     const data = editor.getData();
  //     this.setState({editor:data})
  //     console.log({ event, editor, data });
  // }

  handleOnBlurCkeditor = (event, editor) => {
    console.log("Blur.", editor);
  }

  handleOnFocusCkeditor = (event, editor) => {
    console.log("Focus.", editor);
  }

  handleOnClikShowInfo = () => {
    let condition = this.state.show ? false : true;
    console.log(condition)
    this.setState({ show: condition })
  }

  render() {
    return (
      <>
        <h1>Hola desde editor</h1>
        <button type="button" className="btn btn-default" onClick={this.handleOnClikShowInfo}>Mostrar</button>
        <div className="App">
          <div className="container">
            <h2>Using CKEditor 5 build in React</h2>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "underline",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "todoList",
                  "|",
                  "fontBackgroundColor",
                  "fontColor",
                  "fontFamily",
                  "highlight",
                  "fontSize",
                  "alignment",
                  "outdent",
                  "indent",
                  "|",
                  "code",
                  "imageUpload",
                  "findAndReplace",
                  "blockQuote",
                  "insertTable",
                  "mediaEmbed",
                  "undo",
                  "redo",
                  "sourceEditing",
                  "htmlEmbed",
                  "pageBreak",
                  "horizontalLine",
                ],
              }}
              data={this.state.editor}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                this.setState({ editor: data })
                console.log({ event, editor, data });
              }}
            />
            {this.state.show ? <h2>se debe mostrar</h2> : <h2>No hay nada que mostrar</h2>}
            {this.state.show && ReactHtmlParser(this.state.editor)}
            {this.state.show && this.state.editor2}
          </div>

        </div>
      </>
    )
  }
}

export default Editor
