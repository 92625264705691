import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { BackMessages, Messages, translateDetails } from '../../models/Messages'
import HeaderLogo from './HeaderLogo'
import Footer from "../PublicViews/Footerpersonalizado";
import Error from './Error'

import settings from '../../settings'
import FetchResponses from '../../models/FetchResponses'
import CirculoInformacion from './CirculoInformacion'

class Actualizacion extends Component {

  constructor(props) {
    super(props)
    this.state = {
      resumen: [],
      factura: [],
      circulos: [{
        'nombre': 'PAGAR CREDITOS',
        'link': '#',
        'icon': 'icon dripicons-user-id'
      }, {
        'nombre': 'AUMENTAR CUPO',
        'link': '#',
        'icon': 'icon dripicons-user-id'
      }, {
        'nombre': 'GENERAR CODIGO OTP',
        'link': '#',
        'icon': 'icon dripicons-user-id'
      }, {
        'nombre': 'ACTUALIZAR TUS DATOS',
        'link': '#',
        'icon': 'icon dripicons-user-id'
      }],
      usuarioInfo: {
        'nombre': 'Juan David Garcia',
        'cedula': '1151944063'
      },
      otraCosa: ['hola']
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  consultClient = event => {
    event.preventDefault();
    const data = {
      fiscal_number: this.state.fiscal_number,
      recaptcha_token: this.state.recaptcha_token,
      all_data: false,
    }

    fetch(settings.backend.consult_user, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: settings.headers_super_auth,
    }).then(FetchResponses.processResponse)
      .then(this.successConsultClient)
      .catch(this.failureConsultClient)
  }

  successConsultClient = response => {
    this.setState({ open: true, error_message: 'El cliente entro' });
    //Revisa si existe el cliente
    //Si existe revisar si tiene facturas pendientes.
    //Si tiene facturas redireccionar a la pagina de obligaciones
  }

  failureConsultClient = response => {
    this.setState({ open: true, error_message: 'Falla en la conexión' })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {

    return (
      <div className="gradient-bk">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <div className="row">
              <div className="col-lg-10">
                <h3>
                  {this.state.usuarioInfo.nombre.toUpperCase()} - {this.state.usuarioInfo.cedula}
                </h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="panel">
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    {this.state.circulos.map((info, key) => (
                      <CirculoInformacion key={key} nombre={info.nombre} link={info.link} icon={info.icon} />
                    ))}
                  </div>
                </div>

                <div className="panel">
                  <div className="col-12">
                    Hola amigos desde este módulo
                  </div>
                </div>
                <div className="panel">
                  <div className="col-12">
                    Hola amigos desde este módulo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer main_background />
        <Error
          open={this.state.open}
          error_message={this.state.error_message}
          handleClose={this.handleClose}
        />
      </div>
    )
  }
}

export default Actualizacion;