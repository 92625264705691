import React, { Component } from "react";
import Auth from "../../models/Auth";
import PublicHeader from "./PublicHeader";
// import Footer from "./Footer";
import Footer from "../PublicViews/Footerpersonalizado";
import StaticPageStatus from "../SharedViews/StaticPageStatus";
import Img1 from "../../images/Banner-informacion-de-credito.jpg";
import Img2 from "../../images/Banner-informacion-de-credito-2.png";
import { Helmet } from "react-helmet";
class CreditInformation extends Component {
  constructor(props) {
    super(props);
  }
  element = () => (
    <>
      <Helmet>
          <title>Solicita créditos en línea rápidos - de 5 min| Crédito 20 min </title>
          <meta name="description" content="Facilidades de crédito con solo con el número de cedula. Beneficios como: No hay cuota de manejo, por muerte la deuda quedará condonada. Crédito en 20 minutos" />
      </Helmet>
      <PublicHeader />
      <StaticPageStatus>
        <div className="container-image-complete">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <img
                alt="image-complete1"
                className="image-complete"
                src={Img1}
              />{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="container mt-3 mb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 private-wrapper">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <h1>Información de créditos rápidos y fáciles </h1>{" "}
                </div>{" "}
              </div>
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <img alt="image-complete2" src={Img2} />
                </div>{" "}
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <ul>
                    <li> No te cobramos cuota de manejo </li>{" "}
                    <li>
                      {" "}
                      Estudiamos tu crédito solo presentando tu cédula *{" "}
                    </li>{" "}
                    <li> La cuota expresada es la cuota cobrada </li>{" "}
                    <li> Periodos de pago quincenales y mensuales </li>{" "}
                    <li>
                      {" "}
                      Tú eliges a cuantas cuotas quieres sacar el crédito *{" "}
                    </li>{" "}
                    <li>
                      Puedes pagas tus cuotas en todos los Alkosto y K - Tronix,
                      donde haya presencia del "Kredito en 20 minutos"{" "}
                    </li>{" "}
                    <li>
                      En caso de fallecer el titular de la obligación, la deuda
                      quedará condonada{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </StaticPageStatus>{" "}
      <Footer main_background />
    </>
  );

  render() {
    return Auth.notAuthenticationRequired(this.element());
  }
}

export default CreditInformation;
