import React, { Component } from "react";
import Auth from "../../models/Auth";
import PublicHeader from "./PublicHeader";
// import Footer from "./Footer";
import Footer from "./Footerpersonalizado";
import StaticPageStatus from "../SharedViews/StaticPageStatus";
import Img from "../../images/Banner-lineas-de-atencion.jpg";
import { Helmet } from "react-helmet";
class AttentionLines extends Component {
  constructor(props) {
    super(props);
  }

  element = () => (
    <>

      <Helmet>
          <title>Créditos rápidos online - Numero de contacto. Crédito 20 min </title>
          <meta name="description" content="Líneas de atención, servicio al cliente, Línea nacional 018000 94 6000, Línea gratuita de Whatsapp para solicitar crédito en 5 a 20 minutos con # de cedula" />
      </Helmet>
      <PublicHeader />
      <StaticPageStatus>
        <div className="container-image-complete">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <img alt="image-complete1" className="image-complete" src={Img} />
            </div>{" "}
          </div>{" "}
        </div>
        <div className="container mt-3 mb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 private-wrapper">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <h1 className="mb-5"> Líneas de Atención crédito 20 min </h1>{" "}
                </div>{" "}
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <h2 className="mb-5"> Servicio al cliente </h2>{" "}
                </div>{" "}
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <h3>
                    <span className="icon dripicons-phone" />
                    Medellín:{" "}
                    <a href={`tel:(034) 604 2323`}> (034) 604 2323 </a>{" "}
                  </h3>{" "}
                </div>
                <div className="col-12 col-md-8">
                  <h3>
                    <span className="icon dripicons-phone" />
                    Línea gratuita nacional:{" "}
                    <a href={`tel:018000 94 6000`}> 018000 94 6000 </a>{" "}
                  </h3>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <Footer main_background />
      </StaticPageStatus>{" "}
    </>
  );

  render() {
    return Auth.notAuthenticationRequired(this.element());
  }
}

export default AttentionLines;
