import React, { Component } from "react";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import { Modal } from "antd";
import Auth from "../../models/Auth";
import { Messages, translateDetails } from "../../models/Messages";
import Error from '../PublicViews/Error'

class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      error_message_pop: false,
    };
  }

  confirm = Modal.confirm;

  componentDidMount = () => {
    this.updateContentState();
  };

  updateContentState = () => {
    //Cosas del banner
    let sliders = [];
    let url = settings.backend.list_front_modules;
    fetch(url, {
      method: "GET",
      headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        if (response) {
          let frontModules = response.modules;
          let sliders = [];
          if (frontModules) {
            frontModules.forEach((module) => {
              if (module.module_type === this.props.type) {
                module.content = module.content ? module.content : "";
                module.redirect_link = module.redirect_link
                  ? module.redirect_link
                  : "";
                sliders.push(module);
              }
            });
            sliders.sort(
              (a, b) =>
                a.extra_properties.position - b.extra_properties.position
            );
            this.setState({ fileList: sliders });
          }
        }
      })
      .catch(this.failureDataResponse);
  };

  changueCarrusel = (element) => {
    let content = element.content ? element.content : null;
    let redirect_link = element.redirect_link ? element.redirect_link : null;
    let new_extra_properties = element.extra_properties.position
      ? parseInt(element.extra_properties.position)
      : parseInt(this.state.fileList.length - 1);
    let data = {};
    data = {
      name: element.name,
      image_link: element.image_link,
      content: content,
      extra_properties: {
        position: new_extra_properties,
      },
      module_type: this.props.type,
      redirect_link: redirect_link,
      title: element.name,
    };

    fetch(settings.backend.front_modules, {
      method: "POST",
      body: JSON.stringify(data),
      headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then(this.successChangue)
      .catch((error) => {
        this.handleErrorMessage(Messages.errorTryLater);
      });
  };

  deleteCarrusel = (element) => {
    if (element.create && element.create === false) {
      const data = {
        name: element.name,
        image_link: element.image_link,
        content: element.content,
        extra_properties: {},
        module_type: element.module_type,
        redirect_link: element.redirect_link,
        title: element.name,
      };
      fetch(settings.backend.front_modules, {
        method: "DELETE",
        body: JSON.stringify(data),
        headers: settings.headers_auth(Auth.getSessionToken()),
      })
        .then(FetchResponses.processResponse)
        .then(this.successChangue)
        .catch((error) => {
          this.handleErrorMessage(Messages.errorTryLater);
        });
    } else if (element.create && element.create === true) {
      this.state.fileList.splice(this.state.fileList.length - 1, 1);
      this.setState((prevState) => ({ fileList: [...prevState.fileList] }));
    } else {
      const data = {
        name: element.name,
        image_link: element.image_link,
        content: element.content,
        extra_properties: {},
        module_type: element.module_type,
        redirect_link: element.redirect_link,
        title: element.name,
      };
      fetch(settings.backend.front_modules, {
        method: "DELETE",
        body: JSON.stringify(data),
        headers: settings.headers_auth(Auth.getSessionToken()),
      })
        .then(FetchResponses.processResponse)
        .then(this.successChangue)
        .catch((error) => {
          this.handleErrorMessage(Messages.errorTryLater);
        });
    }
  };

  handleChangeNameCarrusel = (carrusel) => {
    this.state.fileList.forEach((element) => {
      if (element.name === carrusel.target.id) {
        element.name = carrusel.target.value;
        this.setState({ element: element });
      }
    });
  };

  handleChangeRedirectCarrusel = (carrusel) => {
    this.state.fileList.forEach((element) => {
      if (element.name === carrusel.target.id) {
        element.redirect_link = carrusel.target.value;
        this.setState({ element: element });
      }
    });
  };

  handleChangeImgLinkCarrusel = (carrusel) => {
    this.state.fileList.forEach((element) => {
      if (element.name === carrusel.target.id) {
        element.image_link = carrusel.target.value;
        this.setState({ element: element });
      }
    });
  };

  orderCarrusel = (carrusel) => {
    this.state.fileList.forEach((element) => {
      if (element.name === carrusel.target.id) {
        element.extra_properties.position = carrusel.target.value;
        this.setState({ element: element });
      }
    });
  };

  showConfirmSaveCarrusel = (event) => {

    let id = event.target.id;
    let _this = this;
    this.confirm({
      title: "Alkomprar te informa.",
      content: Messages.confirmSaveData,
      okText: "Continuar",
      cancelText: "Cancelar",
      onCancel: true,
      onOk() {
        _this.state.fileList.forEach((element) => {
          if (id === element.name) {
            let invalid_image_redirect =element.image_link.length > 0 && !element.image_link.startsWith("http");
            let invalid_redirect =element.redirect_link.length > 0 && !element.redirect_link.startsWith("http");
            if (invalid_image_redirect || invalid_redirect) {
              _this.handleErrorMessage("Por favor ingresa una url correcta");
            } else {
              _this.changueCarrusel(element);
            }
          }
        });
      },
      onCancel() { },
    });
  };

  showConfirmDeleteCarrusel = (event) => {
    let _this = this;
    let id = event.target.id;
    this.confirm({
      title: "Alkomprar te informa.",
      content: Messages.confirmDeleteData,
      okText: "Continuar",
      onOk() {
        _this.state.fileList.forEach((element) => {
          if (id === element.name) {
            _this.deleteCarrusel(element);
          }
        });
      },
      onCancel() { },
    });
  };

  successChangue = (data) => {
    if (data.detail) {
      let translate = translateDetails(data.detail);
      this.handleErrorMessage(translate);
      this.updateContentState();
    } else {
      this.handleErrorMessage(Messages.successUpdate);
      this.updateContentState();
    }
  };

  handleChangeCarrusel = (type) => {
    let data = {
      name: "data" + Math.random(),
      thumbUrl:
        "https://cdn.pixabay.com/photo/2017/11/10/05/24/upload-2935442_960_720.png",
      content: "",
      module_type: "",
      redirect_link: "",
      image_link:
        "https://cdn.pixabay.com/photo/2017/11/10/05/24/upload-2935442_960_720.png",
      title: "",
      create: true,
      extra_properties: { position: "" },
    };

    switch (type) {
      case "carrusel":
        this.setState((prevState) => ({
          fileList: [...prevState.fileList, data],
        }));
        break;
      case "informacion":
        this.setState((prevState) => ({
          information: [...prevState.information, data],
        }));
        break;
      case "FAQ":
        this.setState((prevState) => ({ FAQ: [...prevState.FAQ, data] }));
        break;
      case "landing":
        this.setState((prevState) => ({
          LandingModule: [...prevState.LandingModule, data],
        }));
        break;
      case "contact":
        this.setState((prevState) => ({
          contactModule: [...prevState.contactModule, data],
        }));
        break;
      default:
        break;
    }
  };

  handleErrorMessage = (error_message_pop = "") => {
    this.setState({ error_message_pop: error_message_pop });
  };
  

  render() {
    return (
      <div className="">
        <Error
              open={!!this.state.error_message_pop}
              error_message={this.state.error_message_pop}
              handleClose={() => this.handleErrorMessage()}
            />
        <div>
          <h3>Carrusel</h3>
        </div>

        {this.state.fileList.map((index) => (
          <div key={index.name}>
            <hr />
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="carrusel--subtitle">Imagen</div>
                <img
                  src={index.image_link}
                  alt="image_link"
                  className="img-thumbnail"
                ></img>
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="row">
                  <div className="col-sm-3 hidden carrusel--center">
                    <span className="carrusel--subtitle">Nombre</span>
                    <input
                      type="text"
                      className="form-control "
                      id={index.name}
                      onChange={this.handleChangeNameCarrusel}
                      value={index.name}
                    />
                  </div>
                  {/* <div className="col-sm-6 carrusel--center">
                  <span className="carrusel--subtitle">Contenido</span>
                  <input type="text" className="form-control " id={index.name}
                    onChange={this.handleChangeTextCarrusel}
                    value={index.content}
                  />
                </div> */}
                  <div className="col-sm-12 carrusel--center">
                    <span className="carrusel--subtitle">Redirección</span>
                    <input
                      type="text"
                      className="form-control "
                      id={index.name}
                      onChange={this.handleChangeRedirectCarrusel}
                      value={index.redirect_link}
                    />
                  </div>
                  <div className="col-sm-9 carrusel--center">
                    <span className="carrusel--subtitle">
                      Link de la imagen
                    </span>
                    <input
                      type="text"
                      className="form-control "
                      id={index.name}
                      onChange={this.handleChangeImgLinkCarrusel}
                      value={index.image_link}
                    />
                  </div>
                  <div className="col-sm-3 carrusel--center">
                    <span className="carrusel--subtitle">Orden</span>
                    <input
                      type="text"
                      className="form-control "
                      id={index.name}
                      onChange={this.orderCarrusel}
                      value={index.extra_properties.position}
                    />
                    {/* <button className="icon dripicons-chevron-up" id="up" value={index.uid}  onClick={this.order} ></button>
                  <button className="icon dripicons-chevron-down" id="down" value={index.uid} onClick={this.order}></button> */}
                  </div>
                  <div className="col-6 carrusel--center">
                    <br></br>
                    {index.create ? (
                      <button
                        id={index.name}
                        className="btn btn-sm btn-success col-12"
                        onClick={this.showConfirmSaveCarrusel}
                      >
                        Crear
                      </button>
                    ) : (
                      <button
                        id={index.name}
                        className="btn btn-sm btn-primary col-12"
                        onClick={this.showConfirmSaveCarrusel}
                      >
                        Actualizar
                      </button>
                    )}
                  </div>
                  <div className="col-6 carrusel--center">
                    <br></br>
                    {index.create ? (
                      <button
                        id={index.name}
                        className="btn btn-sm btn-primary col-12"
                        onClick={this.showConfirmDeleteCarrusel}
                      >
                        Cancelar
                      </button>
                    ) : (
                      <button
                        id={index.name}
                        className="btn btn-sm btn-primary col-12"
                        onClick={this.showConfirmDeleteCarrusel}
                      >
                        Eliminar
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br></br>
          </div>
        ))}

        <div>
          <br></br>
          <button
            className="btn btn-primary col-12 col-md-4"
            onClick={() => this.handleChangeCarrusel("carrusel")}
          >
            Agregar
          </button>
        </div>
      </div>
    );
  }
}

export default Banner;
