import React, { Component } from 'react';
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import ReactHtmlParser from "react-html-parser";

class TabsMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      contents: [],
      contents_length: 0,
      currentContent: '',
      selectedItems: []
    };
  }

  componentDidMount = () => {
    //let firstContentId= !!this.props.section ? this.props.section[0].content_id : null;
    this.setState({ loading: true });
    let url = settings.backend.listContent;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then((response) => {

        this.setState({
          contents: response.contents,
          contents_length: response.contents_length,
          loading: false,
        });
        //this.updateCurrentContent(firstContentId);
      })
      .catch(this.failureDataResponse);
  };

  componentDidUpdate(prevProps) {
    // Uso tipico (no olvides de comparar las props):
    if (this.props.section !== prevProps.section) {
      let firstContentId = !!this.props.section ? this.props.section[0].content_id : null;
      this.updateCurrentContent(firstContentId);
    }
  }

  updateCurrentContent = (contentId) => {
    if (contentId) {
      let selectedItemsNull = this.props.section.map((opcion) => {
        return {
          content_id: opcion.content_id,
          selected: false
        }
      });

      let selectedItems = selectedItemsNull.map((item) => {
        if (Number(item.content_id) === Number(contentId)) {
          return {
            content_id: item.content_id,
            selected: true
          }
        }
        return {
          content_id: item.content_id,
          selected: false
        }
      });
      let currentContent = this.state.contents.filter((content) => Number(content.id) === Number(contentId));
      this.setState({ currentContent, selectedItems })
    }
  }

  handleOnClick = (event) => {
    
    let contentId = event.target.getAttribute("content_id");
    this.updateCurrentContent(contentId);
  }

  render() {
    return (
      <>
        <div className="tab-container">
          <div className="flex-container-mobile">
            {!!this.props.section &&
              this.props.section.length > 0 &&
              this.props.section.sort(function (a, b) {
                if (a.order > b.order) {
                  return 1;
                }
                if (a.order < b.order) {
                  return -1;
                }
                return 0;
              }).filter((option) => option.active === true)
                .map((option, index) => {
                  let opcionSeleccionada=this.state.contents.filter((item) => +item.id === +option.content_id);
                 
                  return (
                    <a href="#section" key={index}>
                      <button
                        key={option.option_id}
                        className={
                          !!this.state.selectedItems.filter((item) => Number(item.content_id) === Number(option.content_id))[0]
                            ? this.state.selectedItems.filter((item) => Number(item.content_id) === Number(option.content_id))[0].selected
                              ? 'non-button button-container-mobile flex-button-selected'
                              : 'non-button button-container-mobile'
                            : 'non-button button-container-mobile'
                        }
                        type="button"
                        content_id={option.content_id}
                        onClick={this.handleOnClick}>
                        
                        {
                          !!(opcionSeleccionada && (opcionSeleccionada.length > 0) ) && <h2 className="text-14">{opcionSeleccionada[0].nombre}</h2> 
                        }
                      </button>
                    </a>
                    
                  )
                })
            }
          </div>
          <div className="flex-container">
            {!!this.props.section &&
              this.props.section.length > 0 &&
              this.props.section.sort(function (a, b) {
                if (a.order > b.order) {
                  return 1;
                }
                if (a.order < b.order) {
                  return -1;
                }
                return 0;
              })
                .filter((option) => option.active === true)
                .map((option, index) => {
                  let opcionSeleccionada=this.state.contents.filter((item) => +item.id === +option.content_id);
                  return (
                    <button
                      // className="flex-item non-button item-selected" 
                      className={
                        !!this.state.selectedItems.filter((item) => Number(item.content_id) === Number(option.content_id))[0]
                          ? this.state.selectedItems.filter((item) => Number(item.content_id) === Number(option.content_id))[0].selected
                            ? 'flex-item non-button item-selected'
                            : 'flex-item non-button'
                          : 'flex-item non-button'
                      }
                      type="button" key={option.option_id}
                      content_id={option.content_id}
                      onClick={this.handleOnClick}>

                      {
                        !!(opcionSeleccionada && (opcionSeleccionada.length > 0) ) && opcionSeleccionada[0].nombre
                      }
                    </button>
                  )
                })
            }
          </div>
          
          {this.state.currentContent != '' &&
            <div className="tab-body" id="section">
              <div>{ReactHtmlParser(this.state.currentContent[0].detalle)} </div>
            </div>
          }
          
        </div>
      </>
    )
  }
}

export default TabsMenu;