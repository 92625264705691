import React, { Component } from "react";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import ReactHtmlParser from "react-html-parser";
import { Redirect } from "react-router";
import Navbar from './Navbar';
import Footer from "./Footerpersonalizado";


class PageView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content_id: null,
      validPage: true,
      content: {}
    };
  }

  getParameters = () => {
    this.setState({ content_id: this.props.match.params.content_id })
    let url = `${settings.backend.detailContent}${this.props.match.params.content_id}`;
    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        if (response) {
          this.setState({ content: response })
        }
      })
      .catch(this.failureContentExist);
  }

  failureContentExist = (response) => {
    this.setState({ validPage: false });
  }

  componentDidMount() {
    this.getParameters();
  }

  componentDidUpdate(prevProps) {
    // Uso tipico (no olvides de comparar las props):
    if (this.props.match.params.content_id !== prevProps.match.params.content_id) {
      this.getParameters();
    }
  }

  render() {
    if (this.state.validPage) {
      return (
        <>
          <Navbar />
          <div className="ck-content">
            <div>{ReactHtmlParser(this.state.content.detalle)} </div>
          </div>

          <Footer main_background />
        </>
      )
    } else {
      return <Redirect to={'/mainpage'} />
    }
  }
}

export default PageView;