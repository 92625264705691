import {
    setDataLanding,
} from '../../../globalState/app';

//state inicial, cada reducer debe tener su propio state
const initialState = {
    landing:  '',
  }
  

export default function(state = initialState, action){
    switch(action.type){
        case setDataLanding:
            return{
                ...state,
                landing: action.payload,
            }
        default:
            return state;
    }
}
