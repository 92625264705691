import React, { Component } from 'react';
import ReactHtmlParser from "react-html-parser";

class Aliados extends Component {
  render() {
    return (
      <div>
        <div className="container-aliados">
          <div className="aliados-title">
            <h4>Aliados:</h4>
          </div>
          <div className="aliados-container-logos">
            {!!this.props.section &&
              this.props.section.length > 0 &&
              this.props.section.sort(function (a, b) {
                if (a.order > b.order) {
                  return 1;
                }
                if (a.order < b.order) {
                  return -1;
                }
                return 0;
              }).filter((option) => option.active === true)
                .map((option, index) => {
                  return (
                    // <div className="aliados-logo">
                    <div key={index} className="ck-content aliados-logo">
                      <div>{ReactHtmlParser(option.detail)} </div>
                    </div>
                    // </div>
                  )
                })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Aliados;