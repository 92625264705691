import React, { Component } from "react";
import { Link, Redirect} from "react-router-dom";
import MenuButton from "./MenuButton";
import MenuOffCanvas from "./MenuOffCanvas";
import Auth from "../../models/Auth";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
// import LogoCredito from '../../images/logo-cred20min.png';
// redux
import { connect } from 'react-redux';

class HeaderPrivate extends Component {
  constructor(props, context) {
    super(props, context);
    let name_avatar = this.get_avatar_name();

    if (name_avatar !== '') {

      this.state = {
        visible: false,
        name_avatar,
        log_out:false,
        width:0,
        height:0,    
      };

    } else {

      this.state = {
        name_avatar: this.props.name_avatar,
        visible: false,
        cont: 1,
        header: '',
        log_out:false,
        width:0,
        height:0,
      
      };
    }

  
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount = () => {
    //Tamaño de la pantalla
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions();

    let url = settings.backend.listMenu;

    fetch(url, {
      method: "GET",
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        if (response) {
          let header = response.Header.filter((item) => item.active === true)
            .sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            });
          header.length > 0
            ? this.setState({ header: header[0].detail })
            : this.setState({ header: '' })
        }
      }).catch(this.failureDataResponse);
  }

  failureDataResponse=()=>{
    //Error
  }

  get_avatar_name = () => {
    let last_user = JSON.parse(Auth.getLastUserData());
    return last_user ? (last_user.first_name.charAt(0) + last_user.last_name.charAt(0)).toUpperCase() : "";
  };

  shouldComponentUpdate = (next_props, next_state) => {
    if (this.props.perfil.name_avatar) {
      let nameAva = (next_props.perfil.first_name.charAt(0) + next_props.perfil.last_name.charAt(0)).toUpperCase();
      if (this.state.cont === 1) {
        this.setState({
          name_avatar: nameAva,
          cont: 2,
        });
      }
    }
    return true;
  }

  handleMouseDown(e) {
    this.toggleMenu();
    e.stopPropagation();
  }

  toggleMenu() {
    this.setState({
      visible: !this.state.visible
    });
  }

  logoutUser = (e) => {
    
    //Condidtion to avoid two calls
    fetch(settings.backend.logout, {
      method: "POST",
      headers: settings.headers_auth(Auth.getSessionToken())
    }).then(FetchResponses.processResponse)
      .then(this.logOutFunction)
      .catch(this.logOutFunction);   
  };

  logOutFunction=()=>{
    Auth.logoutUser();
    let intentos_salir = 0 ;
    do {
      intentos_salir ++;
      if(!localStorage.getItem(Auth.name_token)){ 
        this.setState({log_out:true})
      }
    } while (!!localStorage.getItem(Auth.name_token) || intentos_salir < 5);
    this.setState({log_out:true});
  }

  profileLink = () => {
    if (!Auth.isAdmin()) {
      return <Link to="/perfil" className="dropdown-item">Perfil</Link>;
    }
    return null;
  };

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }


  render() {
      if (this.state.log_out){
        return <Redirect to={this.state.width>'428'? {pathname: '/'} : {pathname: '/login'}} />
      }
      return (
        <div>
          <MenuOffCanvas handleMouseDown={this.handleMouseDown} menuVisibility={this.state.visible}
            menu_list={this.props.menu_list} />
          <nav className="navbar fixed-top">
            <MenuButton handleMouseDown={this.handleMouseDown} />
            <Link to="/resumen">
              <div className="ck-content img-responsive alkomprar-logo" style={{ width: "3em", height: "2em", marginTop: "-2.5em" }}>
                <div>{ReactHtmlParser(this.state.header)} </div>
              </div>
              {/* <img alt="AlkomprarLogo" src={AlkomprarLogo} className="img-responsive alkomprar-logo"/> */}
            </Link>
            <ul className="d-flex align-items-center">
              {/*<img alt="logoCredito20Min" src={LogoCredito} className="img-responsive logoCredito20Min"/>*/}
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <span className="nameAvatar">{this.state.name_avatar}</span>
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {this.profileLink()}
                  {/* <a href="/" className="dropdown-item" disabled={!this.state.log_out_avaiable} onClick={this.logoutUser}>Cerrar Sesión</a> */}
                  <button  
                    className="dropdown-item non-button" 
                    disabled={this.state.log_out} 
                    onClick={this.logoutUser} 
                    style={{fontFamily: "'Quicksand', sans-serif"}}
                  >
                    Cerrar Sesión
                  </button>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      );
   
    
  }
}

HeaderPrivate.propTypes = {
  menu_list: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  perfil: state.perfil.perfilInformation
})

export default connect(mapStateToProps,)(HeaderPrivate);
