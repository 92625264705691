import React, { Component } from "react";
import Error from "./Error";
import FetchResponses from "../../models/FetchResponses";
import TablaObligacion from "./TablaObligacion";
import BarLoader from "react-spinners/BarLoader";

import settings from "../../settings";

class Obligaciones extends Component {
  constructor(props) {
    super(props);
    let invoice = this.props.invoice;
    let custId = this.props.custId;

    this.state = {

      clientInfo: {
        custId: custId,
        invoice: invoice,
      },
      stateLanding: false,
      open: false,
      error_message: "Error al hacer algo",
      planDePagos: [],
      detalleCuota: [],
      detallePago: [],
      loading: true,
    };

    //Llamar la informacion de las listas segun las facturas, y cambia los estados
  }

  componentDidMount = () => {

    this.initializeData();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.invoice !== prevProps.invoice) {
      let invoice = this.props.invoice;
      let custId = this.props.custId;
      this.setState({ clientInfo: { custId, invoice } });
      this.initializeData();
    }

    if (this.props.custId !== prevProps.custId) {
      this.initializeData();
    }
  };

  initializeData = () => {

    !this.state.loading && this.setState({ loading: true });
    //Plan de pagos
    let urlPP = `${settings.backend.planDePagos}/${this.props.custId}/${this.props.invoice}/`
    fetch(urlPP, {
      method: "GET",
      headers: settings.headers_super_auth,
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        // *Poner el parametro de dias_mora como texto para que no aparezca como si fuera un precio
        let array = response.map((payPlan) => {
          let dias_mora = (payPlan.dias_mora).toString();
          return { ...payPlan, dias_mora }
        });

        this.setState({ planDePagos: array });
        this.state.loading && this.setState({ loading: false });

      })
      .catch(this.errorResponse);


    //Detalle de cuota
    let urlDC = `${settings.backend.detalleCuota}/${this.props.custId}/${this.props.invoice}/`
    fetch(urlDC, {
      method: "GET",
      headers: settings.headers_super_auth,
    })
    .then(FetchResponses.processResponse)
    .then((response) => {
    let detalleCuota=response.filter((cuota) => cuota.total>0);
    this.setState({ detalleCuota});
    this.state.loading && this.setState({ loading: false });
    })
    .catch(this.errorResponse);


    //detallepago
    let urlDP = `${settings.backend.detalleDePagos}/${this.props.custId}/${this.props.invoice}`
    fetch(urlDP, {
      method: 'GET',
      headers: settings.headers_super_auth
      // headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then((response) => {
        let detallePago = response.map((plan, key) => {
          let { NUM_CUOTA, DATE, BALANCE } = plan;
          let Balance = BALANCE * -1;
          return { CUOTA: NUM_CUOTA, DATE, Balance };
        });
        this.setState({ detallePago });
        this.state.loading && this.setState({ loading: false });
      });
  };

  errorResponse = (error) => {
    this.setState({ loading: false });
    FetchResponses.errorResponse(error);
  };
  render() {
    if (this.state.loading) {
      return (
        <div className="panel ">
          <div className="row justify-content-center">
            <BarLoader
              sizeUnit="%"
              height={5}
              width={500}
              color="#ff0000"
              loading={this.state.loading}
            />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="panel">
            <div className="row justify-content-center">
              <div className="col-lg-11 col-xl-10">
                <div className="row justify-content-between">
                  <div className="col-4">
                    <h3>
                      {this.state.clientInfo.invoice &&
                        this.state.clientInfo.invoice}
                    </h3>
                  </div>
                  <div className="col-4">
                    <button className="btn btn-secondary" onClick={this.props.handleClickshowSummary}>
                      <i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: '5px' }} />
                      Volver

                    </button>
                  </div>
                </div>
                <TablaObligacion
                  renderPrices
                  title="Plan de pagos"
                  titleCod="planDePagos"
                  invoice={this.state.clientInfo.invoice}
                  dataTitle={[
                    "CUOTA",
                    "FECHA",
                    "VALOR",
                    "ESTADO",
                    "DIAS MORA",
                    "PENDIENTE",
                    "SALDO",
                  ]}
                  data={this.state.planDePagos}
                />
                <TablaObligacion
                  renderPrices
                  title="Detalle de cuota"
                  titleCod="detalleCuota"
                  invoice={this.state.clientInfo.invoice}
                  dataTitle={[
                    "CUOTA",
                    "FECHA PAGO",
                    "FECHA VENCIMIENTO",
                    "INTER MORA",
                    "PENDIENTE POR APLICAR",
                    "VALOR PAGADO",
                  ]}
                  data={this.state.detalleCuota}
                />

                <TablaObligacion
                  renderPrices
                  title="Detalle de Pago"
                  titleCod="detallePago"
                  invoice={this.state.clientInfo.invoice}
                  dataTitle={[
                    "CUOTA", 
                    "FECHA", 
                    "VALOR PAGADO"
                  ]}
                  data={this.state.detallePago}
                />
              </div>
            </div>
            <Error
              open={this.state.open}
              error_message={this.state.error_message}
              handleClose={this.handleClose}
            />
          </div>
        </>
      );
    }
  }
}

export default Obligaciones;
