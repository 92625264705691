import React, { Component } from "react";
// redux
import { connect } from "react-redux";
import { SetMenuManager } from "../PrivateViews/Menu_Actions";
import StaticPageStatus from "../SharedViews/StaticPageStatus";

import HeaderPrivate from "../PrivateViews/HeaderPrivate";
import MenuList from "./MenuList";
import Footer from "../PublicViews/Footerpersonalizado";
import InputMask from 'react-input-mask';
import Auth from "../../models/Auth";
import settings from "../../settings";
import { Messages, translateDetails } from "../../models/Messages";
import Error from "../PublicViews/Error";
import FetchResponses from "../../models/FetchResponses";
import Pagination from "react-js-pagination";


class ControlAdminUsers extends Component {
    constructor(props) {
        super(props);
        let last_user = JSON.parse(Auth.getLastUserData())
        this.state = {
            loading: false,
            open: false,
            fiscal_number: last_user && last_user.fiscal_number ? last_user.fiscal_number : '_',
            error_message: Messages.errorTryLater,
            users: [],
            user_to_save: {
                id: 0,
                fiscal_number: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                password: '',
                is_active: 1
            },
            edit_user: false,
            aux_password: '',
            total_users: 0,
            active_page: 1,
            items_per_page: 10,
            range_pages: 5,
            max_pages: 0,
        }
    }

    componentDidMount = () => {
        this.props.SetMenuManager(["", "", "", "", "", "", "", "submit"]);
        localStorage.setItem("menu", JSON.stringify(["", "", "", "", "", "", "", "submit"]));
        this.updateContentState(this.state.active_page)
    };

    updateContentState = (active_page) => {

        let url = settings.backend.adminUsers
        let params = `?trx_by_page=${this.state.items_per_page}&page=${active_page}&fiscal_number=${this.state.fiscal_number}`
        url += params

        fetch(url, {
            method: "GET",
            headers: settings.headers_auth_file(Auth.getSessionToken()),
        })
            .then(FetchResponses.processResponse)
            .then(({ users, total_users, max_pages, current_page: active_page }) => {

                users = users.sort((a, b) => a.id > b.id ? 1 : -1, 0)
                this.setState({ users, total_users, max_pages, active_page })
            })
            .catch(this.showDataResponse);


    };

    showDataResponse = (error) => {
        this.setState({
            open: true,
            error_message: error.msg || translateDetails(error.detail) || Messages.errorTryLater
        });
    }

    handleEditUser = (event) => {
        let idUser = event.currentTarget.getAttribute("iduser");
        let editableUser = this.state.users.filter((user) => user.id == idUser)[0]
        this.setState({ user_to_save: editableUser, edit_user: true, aux_password: editableUser.password })
    }

    handleAddUser = () => {
        this.initialiceUserState(true)
    }


    handleReturnToAdminUsers = () => {
        this.initialiceUserState(false)
    }

    initialiceUserState = (edit_user = true) => {
        let user_to_save = {
            id: 0,
            fiscal_number: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: ''
        }
        this.setState({ user_to_save, edit_user })
    }

    handleChangeEditUser = (event) => {
        let user_to_save = {
            ...this.state.user_to_save,
            [event.target.id]: event.target.value
        }
        this.setState({ user_to_save });
    }

    handleSaveUser = (event) => {
        event.preventDefault();
        let url = settings.backend.adminUsers;
        let usuario = this.state.user_to_save;

        //Revisar si la contraseña cambio
        if (usuario.password == this.state.aux_password) {
            delete usuario.password
        }
        fetch(url, {
            method: "POST",
            body: JSON.stringify(usuario),
            headers: settings.headers_auth(Auth.getSessionToken()),
        })
            .then(FetchResponses.processResponse)
            .then((response) => {
                if (!response.error) {
                    this.initialiceUserState(false)
                    this.updateContentState(1)
                }
                this.showDataResponse(response)
            })
            .catch(this.showDataResponse);
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handlePageChange = active_page => {
        this.updateContentState(active_page)
    }

    formEditUser = () => {
        return (
            <div>
                <div className="row justify-content-start ps-4 mb-5">
                    <div className="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2 ">
                        <button className="btn btn-primary btn-sm"
                            onClick={this.handleReturnToAdminUsers}>
                            Volver
                        </button>
                    </div>
                </div>

                <form onSubmit={this.handleSaveUser}>

                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="form-group">

                                {
                                    (this.state.user_to_save.id == 0) ? (
                                    <>
                                        <label htmlFor="fiscal_number">(*) Identificacion </label>
                                        <input className="form-control" id="fiscal_number" type="fiscal_number" placeholder="Identificación"
                                            value={this.state.user_to_save.fiscal_number} required
                                            onChange={this.handleChangeEditUser}
                                        />
                                    </>
                                    ) : 
                                    <>
                                        <label htmlFor="fiscal_number"> Identificacion </label>
                                        <h4>{this.state.user_to_save.fiscal_number}</h4>
                                    </>
                                }

                            </div>
                        </div>
                        

                        <div className="col-md-5  offset-md-1">
                            <div className="form-group">
                                <label htmlFor="fiscal_number">Nombre completo </label>
                                <h4>{this.state.user_to_save.first_name}  {this.state.user_to_save.last_name}</h4>
                            </div>
                        </div>

                    </div>
                    
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="firs_name">(*) Nombres </label>
                                <input className="form-control" id="first_name" type="first_name" placeholder="Nombres"
                                    value={this.state.user_to_save.first_name} required
                                    onChange={this.handleChangeEditUser}
                                />
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1">
                            <div className="form-group">
                                <label htmlFor="last_name">(*) Apellidos </label>
                                <input className="form-control" id="last_name" type="last_name" placeholder="Apellidos"
                                    value={this.state.user_to_save.last_name} required
                                    onChange={this.handleChangeEditUser}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="email">(*) E-mail</label>
                                <input className="form-control" id="email" type="email" placeholder="E-mail"
                                    value={this.state.user_to_save.email} required
                                    onChange={this.handleChangeEditUser}
                                />
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1">
                            <div className="form-group">
                                <label htmlFor="phone">(*)Celular</label>
                                <InputMask className="form-control" id="phone" type="text" placeholder="Celular"
                                    maxLength={10}
                                    value={this.state.user_to_save.phone && this.state.user_to_save.phone.replace(/\D|/, '')} required
                                    onChange={this.handleChangeEditUser}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="status">(*) Activo</label>
                                <select
                                    className="form-control form-select form-select-sm"
                                    id="status"
                                    aria-label=".form-select-sm example"
                                    onChange={this.handleChangeEditUser} value={this.state.user_to_save.status}
                                >
                                    <option value="ACTIVE">ACTIVO</option>
                                    <option value="INACTIVE">INACTIVO</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-md-5 offset-md-1">
                            <div className="form-group">
                                <label htmlFor="password">(*) Password </label>
                                <input className="form-control" id="password" type="password" minLength={4} autoComplete="new-password" placeholder="Password"
                                    value={this.state.user_to_save.password} required
                                    onChange={this.handleChangeEditUser}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-6 col-md-5 col-lg-4 ">
                            <button type="submit" className="btn btn-primary btn-block w-100">Guardar</button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }


    element = () => (
        <div className="container-fluid">
            <div className="sidebar">
                <MenuList disabled={this.state.loading} />
            </div>
            <div className="row">
                <div className="col private-wrapper">
                    <HeaderPrivate menu_list={this.state.loading} />

                    <div className="wrapper">
                        <StaticPageStatus>
                            {/* Manager de la FAQ */}
                            <div className="row justify-content-center">
                                <div className="row justify-content-center justify-content-center">
                                    <div className="col-lg-10">
                                        <h1>Usuarios administrativos</h1>
                                    </div>
                                </div>
                                <div className="panel col-lg-10 col-xl-8">
                                    {(!this.state.edit_user) ?
                                        (<div className="row justify-content-center">

                                            <div className="col-lg-12">

                                                <table class="table table-striped" style={{ display: 'table', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                                    <thead>
                                                        <tr style={{ fontSize: '2.1em' }}>
                                                            <th class="text-left-important" scope="col">USUARIO</th>
                                                            <th class="text-left-important" scope="col">NOMBRE</th>
                                                            <th class="text-left-important" scope="col">ESTADO</th>
                                                            <th class="text-left-important" scope="col">EDITAR</th>
                                                        </tr>
                                                    </thead>



                                                    <tbody style={{ fontSize: '1.7em' }}>
                                                        {
                                                            (this.state.users.length > 0) && this.state.users.map((user, index) => {
                                                                return (
                                                                    <tr key={user.id}>
                                                                        <td >{user.fiscal_number} </td>
                                                                        <td >{user.first_name} {user.last_name} </td>
                                                                        <td >{(user.status == 'ACTIVE') ? 'ACTIVO' : 'INACTIVO'} </td>
                                                                        <td >{
                                                                            <button
                                                                                className="non-button ms-2"
                                                                                iduser={user.id}
                                                                                type="button"
                                                                                onClick={this.handleEditUser}
                                                                            >
                                                                                <span className="icon dripicons-pencil" />
                                                                            </button>
                                                                        } </td>
                                                                    </tr>
                                                                )
                                                            })


                                                        }
                                                    </tbody>

                                                </table>

                                                <Pagination
                                                    hideDisabled
                                                    activePage={this.state.active_page}
                                                    itemsCountPerPage={this.state.items_per_page}
                                                    totalItemsCount={this.state.total_users}
                                                    pageRangeDisplayed={this.state.range_pages}
                                                    onChange={this.handlePageChange}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass={`justify-content-center pagination ${this.state.total_users <= this.state.items_per_page ? 'hidden' : ''}`}
                                                />


                                                <div className="ps-4 row">
                                                    <button className="btn btn-primary btn-sm col-sm-4 col"
                                                        onClick={this.handleAddUser}>
                                                        Crear Usuario
                                                    </button>
                                                </div>
                                            </div>
                                        </div>)
                                        : this.formEditUser()
                                    }
                                </div>
                            </div>
                        </StaticPageStatus>
                    </div>
                </div>
            </div>
            <Footer main_background />
            <Error
                open={this.state.open}
                error_message={this.state.error_message}
                handleClose={this.handleClose}
            />
        </div>
    );

    render() {
        return Auth.authenticationRequired(this.element());
    }
}

const mapStateToProps = (state) => ({
    menu: state.menu.menu,
});

export default connect(mapStateToProps, { SetMenuManager })(ControlAdminUsers);
