import React, { Component } from 'react'
import Auth from '../../models/Auth'
// import Footer from './Footer'
import Footer from "./Footerpersonalizado";
import PublicHeader from './PublicHeader'
import InfoModuleList from './InfoModuleList'
import settings from '../../settings'
import FetchResponses from '../../models/FetchResponses'
import StaticPageStatus from '../SharedViews/StaticPageStatus'
import { Helmet } from 'react-helmet';
import Navbar from './Navbar';

class Information extends Component {
  constructor(props) {
    super(props)
    let modules = []
    this.state = {
      modules,
      information: [],
    }
  }

  componentDidMount = () => {
    fetch(settings.backend.list_front_modules, {
      method: 'GET',
      headers: settings.headers_super_auth,
    }).then(FetchResponses.processResponse)
      .then(this.successResponse)
      .catch(error => { })
  }

  successResponse = response => {
    let modules = []
    response.modules.forEach(element => {
      if (element.module_type === 'Information') {
        modules = [...modules, element]
      }
    })
    modules.sort(function (a, b) {
      return a.extra_properties.position - b.extra_properties.position
    })
    this.setState({
      information: response.modules,
      modules,
    })
  }

  element = () => (
    <>
      <Helmet>
        <title>Información - Solicitud de crédito en línea| Crédito 20 min</title>
        <meta name="description" content="Información de pagos crédito 20 minuto. Toda la Información necesaria para tu crédito, puntos de venta, líneas de atención, Ofertas, solicitar crédito online" />
      </Helmet>
      <Navbar />
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 private-wrapper"> { /*Title and text*/}
          <StaticPageStatus>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-10 col-xl-8">
                <h1>Información de interés crédito en minutos </h1>
                
              </div>
            </div>
          </StaticPageStatus>
          <InfoModuleList modules={this.state.modules} />
          
        </div>
      </div>
      <Footer main_background />
    </>
  )

  render() {
    return Auth.notAuthenticationRequired(this.element())
  }
}

export default Information