import React, { Component } from 'react'

class TablaObligacion extends Component {

  constructor(props) {
    super(props);
    let title = this.props.title;
    let invoice = this.props.invoice;
    let dataTitle = this.props.dataTitle;
    let data = this.props.data;
    let titleCod = this.props.titleCod;
    this.state = {
      title,
      titleCod,
      data,
      invoice,
      dataTitle
    };
  };

  render() {
    if (this.props.renderPrices) {
      return (
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="panel" >
              <h5 className=" d-md-block">
                <u className="collapsed" data-bs-toggle="collapse" href={`#${this.state.titleCod}`} role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  nombrelista={this.state.titleCod}>
                  {this.state.title}
                </u>
              </h5>
              <div className="collapse" id={this.state.titleCod}>
                <div className="table-responsive">
                  {this.props.data.length > 0 ?
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          {this.props.dataTitle.map(function (title, key) {
                            return <th key={key} className="text-left-important" scope="col">{title}</th>
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.data.map((dataInd, index) => {
                          let arrayDataInv = Object.values(dataInd);
                          return (
                            <tr key={index}>
                              {arrayDataInv.map(function (value, key) {
                                if ((typeof value) === "number") {
                                  if (key !== 0) {
                                    value = `$ ${value.toLocaleString('de-DE', { maximumFractionDigits: 0 })}`
                                  }
                                }
                                return <td key={key}>{value}</td>
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    : <div className="alert alert-warning">
                      <div className="row justify-content-center">
                        <h3>Sin resultados.</h3>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="table-responsive">
          {this.props.data.length > 0 ?
            <table className="table table-striped">
              <thead>
                <tr>
                  {this.props.dataTitle.map(function (title, key) {
                    return <th key={key} className="text-left-important" scope="col">{title}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {this.props.data.map((dataInd, key) => {
                  let arrayDataInv = Object.values(dataInd);
                  return (
                    <tr key={key}>
                      {arrayDataInv.map(function (value, key) {
                        if ((typeof value) === "number") {
                          if (key !== 0) {
                            value = `$ ${value.toLocaleString('de-DE', { maximumFractionDigits: 0 })}`
                          }
                        }
                        return <td key={key}>{value}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            : <div className="alert alert-warning">
              <div className="row justify-content-center">
                <h3>Sin resultados.</h3>
              </div>
            </div>
          }
        </div>
      )
    }
  }
}

export default TablaObligacion;