import React, { useState, useEffect } from "react";

const MenuSection = ({
  listId,
  opcion,
  section,
  handleChange,
  handleBlurOrder,
  orderMessage,
  handleFirstButton,
  handleSecondButton,
  firstButtonText,
  secondButtonText,
  firstButtonStyle,
  secondButtonStyle,
  createMenuOption = false,
  validOption = {},
  validOrder = true,
  handleChangeToggleOption,
  contents,
  contents_length


}) => {

  const [{ url, page }, setBool] = useState({ url: true, page: true })

  // componentDidMount=()=>{
  //   setBool(opcion.page);
  // };

  // componentDidUpdate=(prevProps)=>{
  //   // Uso tipico (no olvides de comparar las props):
  //   if (opcion.page !== prevProps.page) {
  //     setBool(opcion.page);
  //   }
  // }

  useEffect(() => {
    (opcion.page) ? setBool({ url: false, page: true }) : setBool({ url: true, page: false })
  }, [opcion.page]);


  const handleToggle = (event) => {
    //event.preventDefault();

    if (event.target.checked) {
      let value = event.target.id;
      let urlSelected = value === "urlSelected" ? false : true
      //event.target.checked ? setBool({url:true,page:false}):setBool({url:false,page:true})
      event.target.checked ? setBool({ url: true, page: false }) : setBool({ url: false, page: true })
      //setBool(urlSelected);
      handleChangeToggleOption(urlSelected, section.section_id, opcion.option_id)
    }
  }

  return (
    <>
      <div className="row mt-5">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <span className="carrusel--subtitle">Nombre</span>
          <input
            disabled={!createMenuOption && !opcion.active}
            type="text"
            className="form-control"
            idsection={section.section_id}
            idopcion={opcion.option_id}
            field="name"
            onChange={handleChange}
            value={opcion.name}
          />
          {(validOption.idsection == section.section_id && validOption.option_id == opcion.option_id
            && !validOption.name) && (
              <span> El nombre es obligatorio </span>
            )}
          {createMenuOption && !validOption.name && (
            <span> El nombre es obligatorio </span>
          )}
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <span className="carrusel--subtitle">Orden</span>
          <input
            disabled={!createMenuOption && !opcion.active}
            type="number"
            className="form-control"
            value={opcion.order}
            idsection={section.section_id}
            idopcion={opcion.option_id}
            order={listId}
            field="order"
            onChange={handleChange}
            onBlur={handleBlurOrder}
          />
          {Object.keys(orderMessage).length > 0 &&
            orderMessage.idopcion == opcion.option_id &&
            orderMessage.bool && (
              <span>El número de orden {orderMessage.value} ya existe</span>
            )}
          {(validOption.idsection == section.section_id && validOption.option_id == opcion.option_id
            && !validOption.order) && (
              <>
                <br />
                <span> El orden es obligatorio</span>
              </>
            )}
          {createMenuOption && !validOption.order && (
            <span> El orden es obligatorio </span>
          )}
          {!validOrder && (
            <span> Lo siento, este número ya esta siendo utilizado </span>
          )}
        </div>
        {/* Radio Button */}
        <div className="col-md-4 col-sm-4 col-xs-6 mt-3 mb-3">
          <input type="radio" className="check-input me-3"
            disabled={!createMenuOption && !opcion.active}
            checked={url}
            id="urlSelected"
            onChange={handleToggle}
          />
          <span className="carrusel--subtitle">URL</span>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-6 mt-3 mb-3">
          <input type="radio" className="check-input me-3"
            disabled={!createMenuOption && !opcion.active}
            id="pageSelected"
            checked={page}
            onChange={handleToggle}
          />
          <span className="carrusel--subtitle">Página</span>
        </div>
        {url ? (
          <div className="col-md-12 col-sm-12 col-xs-12 ">
            <span className="carrusel--subtitle">URL</span>
            <input
              disabled={!createMenuOption && !opcion.active}
              type="text"
              className="form-control"
              value={opcion.url || ""}
              idsection={section.section_id}
              idopcion={opcion.option_id}
              field="url"
              onChange={handleChange}
            />
            {(validOption.idsection == section.section_id && validOption.option_id == opcion.option_id
              && (!validOption.url && !opcion.page)) && (
                <span> La url es obligatoria</span>
              )}
            {createMenuOption && (!validOption.url && !opcion.page) && (
              <span> La url es obligatoria </span>
            )}
          </div>) : (
          <div className="col-md-12 col-sm-12 col-xs-12 ">
            <span className="carrusel--subtitle">Página</span>
            <select
              disabled={!createMenuOption && !opcion.active}
              className="form-select form-select-lg"
              value={opcion.content_id || 0}
              idsection={section.section_id}
              idopcion={opcion.option_id}
              field="content_id"
              onChange={handleChange}
            >
              <option defaultValue value="0">
                Seleccione la página correspondiente
              </option>
              {contents_length > 0 &&
                contents.map((page) => (
                  <option key={page.id} value={page.id}>
                    {page.nombre}
                  </option>
                ))}
            </select>
            {(validOption.idsection == section.section_id && validOption.option_id == opcion.option_id
              && (!validOption.page && opcion.page)) && (
                <span> La página es obligatoria</span>
              )}
            {createMenuOption && (!validOption.page && opcion.page) && (
              <span> La página es obligatoria</span>
            )}
          </div>
        )
        }
        <div className="col-md-6 col-sm-6 col-xs-12 d-grid mt-2">
          <button
            disabled={!createMenuOption && !opcion.active}
            className={firstButtonStyle}
            idsection={section.section_id}
            idopcion={opcion.option_id}
            onClick={handleFirstButton}
          >
            {firstButtonText}
          </button>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 d-grid mt-2">
          <button
            
            className={secondButtonStyle}
            idsection={section.section_id}
            idopcion={opcion.option_id}
            optionactive={opcion.active ? '1':'0'}
            onClick={handleSecondButton}
          >
            {secondButtonText}
          </button>
        </div>
      </div>
      <hr />
    </>
  );
};

export default MenuSection;
