import React, { Component } from 'react';
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import ReactHtmlParser from "react-html-parser";
import Navbar from './Navbar';
import Footer from './Footer';

class NotFound extends Component {

  constructor(props) {
    super(props);
    this.state = {
      contents: [],
      contents_length: 0,
      currentContent: '',
      selectedItems: []
    };
  }

  componentDidMount = () => {
   console.log('Hola a not found')
  };

  render() {
    return (
      <>
        <Navbar />
        <div className="container" style={{margin:"10rem"}}>
      
         Ups, página no encontrada, intente nuevamente.
        </div>
        <Footer main_background />
      </>
    )
  }
}

export default NotFound;