import {
    setPDFInformation,
    setPDFNavigation
} from '../../../globalState/app';

//state inicial, cada reducer debe tener su propio state
const initialState = {
    PDF: '',

  }
  

export default function(state = initialState, action){
    switch(action.type){
        case setPDFInformation:
            return{
                ...state,
                PDFInformation: action.payload,
            }
        case setPDFNavigation:
            return {
              ...state,
              PDF: action.payload,
            }
        default:
            return state;
    }
}
