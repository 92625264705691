import React from "react";
import "antd/dist/antd.css";
import { Modal } from "antd";

export default class Icons extends React.Component {

  state = { open: false };

  showIcons = (icon) => {
    this.props.iconSelect(icon.target.id);
  }
  render() {

    let iconos = [
      "icon dripicons-alarm",
      "icon dripicons-align-center",
      "icon dripicons-align-justify",
      "icon dripicons-align-left",
      "icon dripicons-align-right",
      "icon dripicons-anchor",
      "icon dripicons-archive",
      "icon dripicons-arrow-down",
      "icon dripicons-arrow-left",
      "icon dripicons-arrow-right",
      "icon dripicons-arrow-thin-down",
      "icon dripicons-arrow-thin-left",
      "icon dripicons-arrow-thin-right",
      "icon dripicons-arrow-thin-up",
      "icon dripicons-arrow-up",
      "icon dripicons-article",
      "icon dripicons-backspace",
      "icon dripicons-basket",
      "icon dripicons-basketball",
      "icon dripicons-battery-full",
      "icon dripicons-battery-full",
      "icon dripicons-battery-low",
      "icon dripicons-battery-medium",
      "icon dripicons-bell",
      "icon dripicons-blog",
      "icon dripicons-bluetooth",
      "icon dripicons-bold",
      "icon dripicons-bookmark",
      "icon dripicons-bookmarks",
      "icon dripicons-box",
      "icon dripicons-briefcase",
      "icon dripicons-brightness-low",
      "icon dripicons-brightness-max",
      "icon dripicons-brightness-medium",
      "icon dripicons-broadcast",
      "icon dripicons-browser",
      "icon dripicons-browser-upload",
      "icon dripicons-brush",
      "icon dripicons-calendar",
      "icon dripicons-camcorder",
      "icon dripicons-camera",
      "icon dripicons-card",
      "icon dripicons-cart",
      "icon dripicons-checklist",
      "icon dripicons-checkmark",
      "icon dripicons-chevron-down",
      "icon dripicons-chevron-left",
      "icon dripicons-chevron-right",
      "icon dripicons-chevron-up",
      "icon dripicons-clipboard",
      "icon dripicons-clock",
      "icon dripicons-clockwise",
      "icon dripicons-cloud",
      "icon dripicons-cloud-download",
      "icon dripicons-cloud-upload",
      "icon dripicons-code",
      "icon dripicons-contract",
      "icon dripicons-contract-2",
      "icon dripicons-conversation",
      "icon dripicons-copy",
      "icon dripicons-crop",
      "icon dripicons-cross",
      "icon dripicons-crosshair",
      "icon dripicons-cutlery",
      "icon dripicons-device-desktop",
      "icon dripicons-device-mobile",
      "icon dripicons-device-tablet",
      "icon dripicons-direction",
      "icon dripicons-disc",
      "icon dripicons-document",
      "icon dripicons-document-delete",
      "icon dripicons-document-edit",
      "icon dripicons-document-new",
      "icon dripicons-document-remove",
      "icon dripicons-dot",
      "icon dripicons-dots-2",
      "icon dripicons-dots-3",
      "icon dripicons-download",
      "icon dripicons-duplicate",
      "icon dripicons-enter",
      "icon dripicons-exit",
      "icon dripicons-expand",
      "icon dripicons-expand-2",
      "icon dripicons-experiment",
      "icon dripicons-export",
      "icon dripicons-feed",
      "icon dripicons-flag",
      "icon dripicons-flashlight",
      "icon dripicons-folder",
      "icon dripicons-folder-open",
      "icon dripicons-forward",
      "icon dripicons-gaming",
      "icon dripicons-gear",
      "icon dripicons-graduation ",
      "icon dripicons-graph-bar ",
      "icon dripicons-graph-line ",
      "icon dripicons-graph-pie ",
      "icon dripicons-headset ",
      "icon dripicons-heart ",
      "icon dripicons-help ",
      "icon dripicons-home ",
      "icon dripicons-hourglass ",
      "icon dripicons-inbox ",
      "icon dripicons-information ",
      "icon dripicons-italic ",
      "icon dripicons-jewel ",
      "icon dripicons-lifting ",
      "icon dripicons-lightbulb ",
      "icon dripicons-link ",
      "icon dripicons-link-broken ",
      "icon dripicons-list ",
      "icon dripicons-loading ",
      "icon dripicons-location ",
      "icon dripicons-lock ",
      "icon dripicons-lock-open ",
      "icon dripicons-mail ",
      "icon dripicons-map ",
      "icon dripicons-media-loop ",
      "icon dripicons-media-next ",
      "icon dripicons-media-pause ",
      "icon dripicons-media-play ",
      "icon dripicons-media-previous ",
      "icon dripicons-media-record ",
      "icon dripicons-media-shuffle ",
      "icon dripicons-media-stop ",
      "icon dripicons-medical ",
      "icon dripicons-menu ",
      "icon dripicons-message ",
      "icon dripicons-meter ",
      "icon dripicons-microphone ",
      "icon dripicons-minus ",
      "icon dripicons-monitor ",
      "icon dripicons-move ",
      "icon dripicons-music ",
      "icon dripicons-network-1 ",
      "icon dripicons-network-2 ",
      "icon dripicons-network-3 ",
      "icon dripicons-network-4 ",
      "icon dripicons-network-5 ",
      "icon dripicons-pamphlet ",
      "icon dripicons-paperclip ",
      "icon dripicons-pencil ",
      "icon dripicons-phone ",
      "icon dripicons-photo ",
      "icon dripicons-photo-group ",
      "icon dripicons-pill ",
      "icon dripicons-pin ",
      "icon dripicons-plus ",
      "icon dripicons-power ",
      "icon dripicons-preview ",
      "icon dripicons-print ",
      "icon dripicons-pulse ",
      "icon dripicons-question ",
      "icon dripicons-reply ",
      "icon dripicons-reply-all ",
      "icon dripicons-return ",
      "icon dripicons-retweet ",
      "icon dripicons-rocket ",
      "icon dripicons-scale ",
      "icon dripicons-search ",
      "icon dripicons-shopping-bag ",
      "icon dripicons-skip ",
      "icon dripicons-stack ",
      "icon dripicons-star ",
      "icon dripicons-stopwatch ",
      "icon dripicons-store ",
      "icon dripicons-suitcase ",
      "icon dripicons-swap ",
      "icon dripicons-tag ",
      "icon dripicons-tag-delete ",
      "icon dripicons-tags ",
      "icon dripicons-thumbs-down ",
      "icon dripicons-thumbs-up ",
      "icon dripicons-ticket ",
      "icon dripicons-time-reverse ",
      "icon dripicons-to-do ",
      "icon dripicons-toggles ",
      "icon dripicons-trash ",
      "icon dripicons-trophy ",
      "icon dripicons-upload ",
      "icon dripicons-user ",
      "icon dripicons-user-group ",
      "icon dripicons-user-id ",
      "icon dripicons-vibrate ",
      "icon dripicons-view-apps ",
      "icon dripicons-view-list ",
      "icon dripicons-view-list-large ",
      "icon dripicons-view-thumb ",
      "icon dripicons-volume-full ",
      "icon dripicons-volume-low ",
      "icon dripicons-volume-medium ",
      "icon dripicons-volume-off ",
      "icon dripicons-wallet ",
      "icon dripicons-warning ",
      "icon dripicons-web ",
      "icon dripicons-weight ",
      "icon dripicons-wifi ",
      "icon dripicons-wrong ",
      "icon dripicons-zoom-in ",
      "icon dripicons-zoom-out",

    ];
    return (
      <div>
        <Modal
          visible={this.props.open}
          onOk={this.handleOk}
          onCancel={this.props.handleClose}
          onClose={this.props.handleClose}
          footer={null}
          centered={false}>
          {iconos.map(index => {
            return <button id={index} className={index} onClick={this.showIcons}></button>
          })}
        </Modal>
      </div>
    );
  }
}