import React, { Component } from 'react'
import TagManager from 'react-gtm-module'
import { Link } from 'react-router-dom'
import HeaderLogo from './HeaderLogo'
// import Footer from './Footer'
import Footer from "../PublicViews/Footerpersonalizado";
import Logger from '../../models/Logger'
import Auth from '../../models/Auth'
import settings from '../../settings'
import FetchResponses from '../../models/FetchResponses'
import TerminosCondiciones from './TerminosCondiciones'
import CodigoRegistro from './CodigoRegistro'
import Error from './Error'
import { BackMessages, Messages, translateDetails } from '../../models/Messages'
import ReCaptcha from './ReCaptcha'
import StaticPageStatus from '../SharedViews/StaticPageStatus'
import { Helmet } from 'react-helmet';
import { generateSHA256Hash } from '../../models/Helpers';

class Registro extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fiscal_number: '',
            first_name: '',
            last_name: '',
            email: '',
            showEmail: '',
            phone: '',
            captcha_value: '',
            captcha_checked: false,
            recaptchaRef: React.createRef(),
            is_valid_user: false,
            is_valid_fiscal_number: false,
            show_terminos: false,
            openCodigoRegistro: false,
            error_message: '',
            open: false,
            TC_messaje: '',
            open_TC: false,
            otp: '',
            datosOriginales: false,
            loading: true,
        }
        Logger.setLogger(this.constructor.name)
    }

    handleChange = event => {
        if (event.target.id !== 'showEmail') {
            this.setState({
                [event.target.id]: event.target.value,
            })
        } else {
            if (event.target.value.indexOf('*')) {
                this.setState({
                    [event.target.id]: event.target.value,
                })
            }
            if (event.target.value.indexOf('*') !== 3) {
                this.setState({
                    email: event.target.value,
                })
            } else {
                this.setState({
                    [event.target.id]: event.target.value,
                })
            }
        }
    }

    validateFiscalNumber = () => {
        return this.state.fiscal_number.length >= 4 && this.state.fiscal_number.length <= 13
    }

    showCaptcha = () => {
        return this.validateFiscalNumber() && !this.state.captcha_checked
    }

    reCaptchaOnChange = value => {
        if (value) {
            
            this.sendDataLayerSolicitarClave()
            this.setState({
                captcha_value: value,
                captcha_checked: true,
            })
            this.consultClient()
        }
    }

    successConsultClient = response => {
        Logger.trace('Consult Client success: ' + JSON.stringify(response))
        let { fiscal_number, first_name, last_name, email, phone } = response
        let showEmail = ''

        if (fiscal_number) {
            if (email && email !== 'sincorreo@sincorreo.com') {
                let arraySubChar = response.email.split('@')
                let newArrayChar = arraySubChar[0]
                let finalChar = ''
                for (let i = 0; i < arraySubChar[0].length; i++) {
                    if (i >= 3) {
                        finalChar = finalChar + '*'
                    } else {
                        finalChar = finalChar + newArrayChar[i]
                    }
                }
                showEmail = finalChar + '@' + arraySubChar[1]
            } else {
                email = ''
            }
            this.setState({
                fiscal_number,
                first_name,
                last_name,
                email,
                phone,
                showEmail,
                is_valid_user: true,
            })
            Auth.setLastUserData(response)
        } else {
            this.failureConsultClient(response)
        }
    }
    successConsultClientotp = response => {
        Logger.trace('Consult Client success: ' + JSON.stringify(response))
        let { fiscal_number, first_name, last_name, email, phone } = response
        let errmsg = (Array.isArray(response.mensaje)) ? response.mensaje[0] : response.mensaje
        let showEmail = ''
        let open = true
        let error_message = translateDetails(errmsg)
        this.setState({ open, error_message, loading: false, })
    }

    failureConsultClient = response => {
        Logger.trace('Reset captcha')
        this.state.recaptchaRef.current.reset()
        let error_message =
            response.detail === BackMessages.userAlreadyExists ?
                Messages.userAlreadyExists :
                Messages.userDoesNotExists
        if (response.detail === BackMessages.wrongdata) {
            error_message = Messages.wrongdata;
        }
        this.setState({
            captcha_value: '',
            captcha_checked: false,
            open: true,
            error_message,
        })
    }

    consultClient = () => {
        Logger.trace('Consult client with fiscal number: ' + this.state.fiscal_number)
        const data = {
            fiscal_number: this.state.fiscal_number,
            recaptcha_token: this.state.captcha_value,
            all_data: false,
        }
        fetch(settings.backend.send_otp, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: settings.headers_super_auth,
        })
            .then(FetchResponses.processResponse)
            .then(this.successConsultClient)
            .catch(this.failureConsultClient)
    }

    sendOtp = event => {
        event.preventDefault();

        const data = {
            fiscal_number: this.state.fiscal_number,
            recaptcha_token: this.state.captcha_value,
            all_data: false,
        }
        fetch(settings.backend.send_otp, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: settings.headers_super_auth,
        })
            .then(FetchResponses.processResponse)
            .then(this.successConsultClientotp)
            .catch(this.failureConsultClient)
    }

    successPreRegisterClient = response => {
        this.setState({ loading: false })
        if (response.estado === "ERROR") {
            let errmsg = (Array.isArray(response.mensaje)) ? response.mensaje[0] : response.mensaje
            let open = true
            let error_message = translateDetails(errmsg)
            this.setState({ open, error_message })
            return
        }
        Logger.trace('PreRegister success: ' + JSON.stringify(response))
        this.setState({ openCodigoRegistro: true })
    }

    failurePreRegisterClient = response => {
        this.setState({ loading: false })
        let open = true
        let error_message = translateDetails(response.detail)
        this.setState({ open, error_message })
    }
    validationOtp = event => {
        event.preventDefault()
        let { fiscal_number, otp } = this.state
        const data = {
            "documento": fiscal_number,
            "codigo": otp
        }
        this.setState({ loading: true })
        fetch(settings.backend.validate_otp, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: settings.headers_super_auth,
        })
            .then(FetchResponses.processResponse)
            .then(this.successPreRegisterClient)
            .catch(this.failurePreRegisterClient)
    }
    preRegisterUser = event => {
        event.preventDefault()
        let { fiscal_number, first_name, last_name, email, phone } = this.state
        const data = {
            fiscal_number,
            first_name,
            last_name,
            email,
            phone,
        }
        this.setState({ loading: true })
        fetch(settings.backend.pre_register, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: settings.headers_super_auth,
        })
            .then(FetchResponses.processResponse)
            .then(this.successPreRegisterClient)
            .catch(this.failurePreRegisterClient)
    }

    handleClickOpen = () => {
        this.setState({
            open_TC: true,
        })
    }

    registerClose = () => {
        this.setState({ openCodigoRegistro: false })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    handleClose_TC = () => {
        this.setState({ open_TC: false })
    }

    sendDataLayerSolicitarClave = async () => {
        let userid = await generateSHA256Hash(this.state.fiscal_number)
        let tagManagerArgs ={
            event:'Solicitar-Clave',
            category:'Interacciones Login',
            action:'Click',
            label:'solicitar clave',
            userid
        }
        
        TagManager.dataLayer({
          dataLayer: tagManagerArgs
        })
    
      }

    element = () => {
        return (
            <>
                <Helmet>
                    <title>Créditos online rápido solo con cedula | Crédito 20 min </title>
                    <meta name="description" content="Ingresa y regístrate para tener un crédito entre 5 minutos o 20 min. Solicita un crédito online solo con la cedula. Prueba registrando tu # de cedula" />
                </Helmet>

                <div className="gradient-bk" >
                    <div className="container" >
                        <div className="row justify-content-md-center" >
                            <div className="col col-lg-5" >
                                <HeaderLogo />
                            </div>
                        </div >
                        <div className="row justify-content-md-center" >
                            <StaticPageStatus >
                                <div className="col col-lg-9 col-xl-8" >
                                    <div className="whitePanel register" >
                                        <div className="row" >
                                            <div className="col-md-6" >
                                                <div style={
                                                    { minHeight: 330 }
                                                } >
                                                    <form onSubmit={this.validationOtp} >
                                                        <h1 > Regístrate para solicitar crédito en 20 min </h1>

                                                        <div className="form-group" >
                                                            <span className="icon dripicons-user-id" />
                                                            <input type="text"
                                                                className="form-control "
                                                                id="fiscal_number"
                                                                placeholder="Ingresa tu Cédula"
                                                                required minLength={4}
                                                                maxLength={13}
                                                                autoFocus autoComplete="off"
                                                                readOnly={this.state.is_valid_user}
                                                                value={this.state.fiscal_number}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div >

                                                        <ReCaptcha recaptchaRef={this.state.recaptchaRef}
                                                            reCaptchaOnChange={this.reCaptchaOnChange}
                                                            hidden={!this.showCaptcha()}
                                                        />

                                                        <div className="form-group" >
                                                            <span className="icon dripicons-user"
                                                                hidden={!this.state.is_valid_user}
                                                            /> <input type="text"
                                                                className="form-control "
                                                                id="full_name"
                                                                placeholder="Nombre Completo"
                                                                required readOnly value={this.state.first_name + ' ' + this.state.last_name}
                                                                hidden={!this.state.is_valid_user}
                                                            />
                                                        </div >

                                                        <div className="form-group form-check"
                                                            hidden={!this.state.is_valid_user} >
                                                            <label className="form-check-label" >
                                                                Hemos enviado un codigo al número {this.state.phone} < br />
                                                                y al correo {this.state.email} </label>
                                                        </div >

                                                        <div className="form-group" >

                                                            <input type="text"
                                                                className="form-control"
                                                                id="otp"
                                                                placeholder="Ingrese código OTP"
                                                                required hidden={!this.state.is_valid_user}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div >


                                                        <div className="form-group form-check"
                                                            hidden={!this.state.is_valid_user} >
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                id="AceptoPolitica"
                                                                required />
                                                            <label className="form-check-label"
                                                                htmlFor="AceptoPolitica" >
                                                                He leído y acepto la
                                                                <a href={settings.terms_and_conditions} rel="noopener noreferrer" target="_blank"> {' '}
                                                                    política de privacidad de datos </a> </label >
                                                        </div>

                                                        <div className="form-group" >
                                                            <button type="button"
                                                                className="btn btn-primary btn-md"
                                                                hidden={!this.state.is_valid_user}
                                                                onClick={this.sendOtp} >
                                                                Reenviar OTP </button> <button type="submit"
                                                                    className="btn btn-primary btn-md"
                                                                    hidden={!this.state.is_valid_user} >
                                                                Confirmar </button>
                                                        </div >
                                                    </form>

                                                    <TerminosCondiciones open_TC={this.state.open_TC}
                                                        TC_messaje={this.state.TC_messaje}
                                                        handleClose_TC={this.handleClose_TC}
                                                    />

                                                    <CodigoRegistro openCodigoRegistro={this.state.openCodigoRegistro}
                                                        registerClose={this.registerClose}
                                                        preRegisterUser={this.preRegisterUser}
                                                        loading={this.state.loading}
                                                        fiscal_number={this.state.fiscal_number}
                                                    />
                                                </div >
                                            </div>
                                            <div className="col-md-6" >
                                                <div className="text-right" >
                                                    <Link to="/" > Volver a Inicio </Link>
                                                </div >
                                            </div>
                                        </div >
                                    </div>
                                </div >
                            </StaticPageStatus> </div > </div>  <Footer main_background white_background />
                    <Error open={this.state.open}
                        error_message={this.state.error_message}
                        handleClose={this.handleClose}
                    />
                </div >
            </>
        )
    }

    render() {
        return Auth.notAuthenticationRequired(this.element())
    }
}

export default Registro