import React from 'react';
import ReactDOM from 'react-dom';
import './styles/App.css';
import App from './components/App';
import Logger from './models/Logger';
import * as serviceWorker from './serviceWorker';

// Init logger
Logger.init();

ReactDOM.render(  <App /> , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    console.log('Nueva versión disponible. Por favor, recarga la página.');
  },
  updateViaCache: 'none',
});
