import {
    setDataMenu
} from '../../../globalState/app';

//state inicial, cada reducer debe tener su propio state
var guardado = localStorage.getItem('menu');
const initialState = {
    menu: JSON.parse(guardado)? JSON.parse(guardado):["submit","","","","","",""]
  }
  

export default function(state = initialState, action){
    switch(action.type){
        case setDataMenu:
            return{
                ...state,
                menu: action.payload,
            }
        default:

            return state;
    }
}
