import React, { Component } from 'react';
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
import ReactHtmlParser from "react-html-parser";

class LandingMain extends Component {
  componentDidUpdate(prevProps) {
    // Uso tipico (no olvides de comparar las props):
    if (this.props.section !== prevProps.section) {
      //console.log('Estas son las props del landign page',this.props.section)
      // let firstContentId= !!this.props.section ? this.props.section[0].content_id : null;
      // this.updateCurrentContent(firstContentId);
    }
  }

  render() {
    return (
      <div className="container-landing">
        {!!this.props.section &&
          this.props.section.length > 0 &&
          this.props.section.sort(function (a, b) {
            if (a.order > b.order) {
              return 1;
            }
            if (a.order < b.order) {
              return -1;
            }
            return 0;
          }).filter((option) => option.active === true)
            .map((option, index) => {
              return (
                // <div className="aliados-logo">
                <div key={index} className="ck-content landing-item">
                  <div>{ReactHtmlParser(option.detail)} </div>
                </div>
                // </div>
              )
            })}
      </div>)
  }
}

export default LandingMain;