import React, { Component } from "react";
import Logger from "../../models/Logger";
import Auth from "../../models/Auth";
// import PayInvoice from "./PayInvoice";
import settings from "../../settings";
import FetchResponses from "../../models/FetchResponses";
// import Error from "../PublicViews/Error";
import {
  translateDetails,
} from "../../models/Messages";
import FacturaView from "../PublicViews/FacturaView";

class Facturas extends Component {
  constructor(props) {
        super(props);
        let last_user = JSON.parse(Auth.getLastUserData())
        this.state = {
          loading: true,
          fiscal_number: last_user && last_user.fiscal_number ? last_user.fiscal_number : '_',
          clientInvoices:{},
          carteraCastigada:false,
        };
        Logger.setLogger(this.constructor.name);
      }

  componentDidMount() {
    this.setState({
      loading: true
    });

    fetch(settings.backend.invoices, {
        method: "GET",
        headers: settings.headers_auth(Auth.getSessionToken()),
    })
      .then(FetchResponses.processResponse)
      .then(this.successDataResponse)
      .catch(this.checkPunishedPortfolio);
  }

  checkPunishedPortfolio=()=>{
    //Llamar cartera castigada
    this.setState({carteraCastigada:true});
    let url=`${settings.backend.punishedPortfolio}/${this.state.fiscal_number}`;
    fetch(url, {
      method: 'GET',
      headers: settings.headers_super_auth,
    })
    .then(FetchResponses.processResponse)
    .then(this.successDataResponse)
    .catch(this.failureDataResponse)
  }

  successDataResponse=(response) => {
    this.setState({clientInvoices:response})
  }

  
  failureDataResponse = response => {
    let translate = translateDetails(response.detail);
    this.setState({
      open: true,
      error_message: translate,
      loading: false
    });
  };

  render() {
    return <FacturaView 
      clientInvoices={this.state.clientInvoices}
      loading={this.state.loading}
      carteraCastigada={this.state.carteraCastigada}
      privateView
    />
  }
    
}

export default Facturas